import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../../foundations/Typography';
import { Row } from '../../../foundations/Grid';

// Handles embedded variant heading AND pagination styles
export const TypographyStyled = styled(Typography)`
  ${({ theme, themeType }) => {
    const mediumUp = theme.mediumUp();

    return css`
      && {
        position: relative;
        padding-bottom: ${mediumUp ? rem(40) : rem(24)};
        font-weight: 400;
        text-align: center;

        && {
          ${themeType === 'nmx-pcg' && (`
            text-transform: uppercase;
            letter-spacing: ${rem(4)};
            font-weight: 400;
          `)}
        }
      }
    `;
  }}
`;

export const TypographyHeroHeadingStyled = styled(Typography)`
  ${({ theme, isWelcomeView }) => {
    let paddingBottom = rem(24);
    const mediumUp = theme.mediumUp();

    if (mediumUp) {
      paddingBottom = rem(40);
    }

    return css`
      position: relative;
      color: ${theme.colors.neutral.white};
      text-align: center;
      ${!isWelcomeView && `padding-bottom: ${paddingBottom}`}
    `;
  }}
`;

export const DivPaginationContainerStyled = styled.div`
  ${({ theme }) => {
    let top = 12;
    let right = 5;
    let paddingRight = 0;
    const mediumUp = theme.mediumUp();
    const xlargeUp = theme.xlargeUp();

    if (mediumUp) {
      top = 26;
    }

    if (xlargeUp) {
      paddingRight = 25;
      right = 0;
    } else if (mediumUp) {
      right = 20;
    }

    return css`
      position: absolute;
      right: ${rem(right)};
      top: ${rem(top)};
      padding-right: ${rem(paddingRight)};
    `;
  }}
`;

export const RowHeroHeadingStyled = styled(Row)`
  ${({ theme }) => {
    let paddingHorizontal = rem(20);
    const mediumUp = theme.mediumUp();

    if (mediumUp) {
      paddingHorizontal = rem(20);
    }
    return css`
      padding-left: ${paddingHorizontal};
      padding-right: ${paddingHorizontal};
    `;
  }}
`;

export const SpanNoBreakStyled = styled.span`
  ${() => css`
    white-space: nowrap;
  `}
`;
