import React from 'react';
import PropTypes from 'prop-types';

// TODO: this whole Component could be structured like the other Components in the ComponentLibrary, with a styles.js, etc.

const Box = ({ sx, children }) => {
  const multiplier = 16;

  const { pb, pt, mb, mt } = sx || {};

  const paddingBottom = pb ? { paddingBottom: pb * multiplier } : {};

  const paddingTop = pt ? { paddingTop: pt * multiplier } : {};

  const marginBottom = mb ? { marginBottom: mb * multiplier } : {};

  const marginTop = mt ? { marginTop: mt * multiplier } : {};

  const styles = {
    ...paddingBottom,
    ...paddingTop,
    ...marginBottom,
    ...marginTop,
  };

  return (
    <div style={styles}>
      {children}
    </div>
  );
};

Box.propTypes = {
  sx: PropTypes.shape({
    pb: PropTypes.number,
    pt: PropTypes.number,
    mb: PropTypes.number,
    mt: PropTypes.number,
  }),
};

Box.defaultProps = {
  pb: 0,
  pt: 0,
  mb: 0,
  mt: 0,
};

export default Box;
