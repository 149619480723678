import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import Button from '../../../../components/Button';
import Link from '../../../../components/Link';

export const NavStyled = styled.nav`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      margin-bottom: ${mediumUp ? 0 : rem(16)};
    `;
  }};
`;

export const ListItemStyled = styled.li`
 ${({ theme }) => css`
  margin-bottom: ${rem(10)};
  /* style overrides to remove OneTrust button styles, set redundantly as a fallback */
  #ot-sdk-btn.ot-sdk-show-settings.nmx-override {
    height: unset;
    padding: unset;
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    color: ${theme.colors.neutral.grayDark20};
    white-space: unset;
    word-wrap: unset;
    border: unset;
    cursor: unset;
    transition: unset;
    &:hover {
      color: ${theme.colors.action.lightTheme.linkHover};
      background-color: unset;
    }
  `}
}`;

export const LinkStyled = styled(Link)`
  ${({ theme }) => css`
    && {
      color: ${theme.colors.neutral.grayDark20};
      font-size: ${rem(14)};
      line-height: ${rem(22)};
      font-weight: 500;
      text-decoration: none;
      &:visited {
        color: ${theme.colors.neutral.grayDark20};
      }
    }
  `};
`;

export const ButtonStyled = styled(Button)`
  ${({ theme }) => css`
    && {
      min-width: unset;
      min-height: unset;
      padding: 0;
      color: ${theme.colors.neutral.grayDark20};
      font-size: ${rem(14)};
      line-height: ${rem(22)};
      text-align: left;
      :hover {
        color: ${theme.colors.action.lightTheme.linkHover};
        text-decoration: underline;
        text-underline-offset: ${rem(5)};
      }
    `}
}`;
