// deprecated
import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
// helpers
import { isEmpty } from '@nmx/utils/dist/utilities/validators';
import { PHONE_MEDIA_QUERY } from '../../../../assets/js/constants/media_query.constants';
import resolveFrPhoto from '../../../../assets/js/utils/resolveFrPhoto.util';
import sanitizePhone from '../../../../assets/js/helpers/sanitizePhone';
// native components
import Placeholder from '../Placeholder/placeholder';
// template components
import { Col, Row } from '../../../foundations/Grid';
import AgentForm from '../../AgentForm';
import Button from '../../../components/Button';
import CaratRightIcon from '../../../foundations/Icon/icons/utility/CaratRight';
import Ensemble from './Ensemble';
import Link from '../../../components/Link';
import Typography from '../../../foundations/Typography';
// styles
import {
  ColFormContainerStyled,
  ColPhotoContainerStyled,
  DivAgentCardWrapperStyled,
  ImgStyled,
} from './styles';

const AgentCard = ({
  applicationId,
  agentObject,
  campaign,
  config,
  customName,
  photoWidth,
  searchAnalytics,
  source,
  themeType,
  topic,
}) => {
  const theme = useContext(ThemeContext);
  const smallDown = theme.smallDown();
  const [isExpanded, setIsExpanded] = useState(false);
  const rep = agentObject;

  const getStandardizedTitle = () => {
    if (rep.type === 'MD') {
      return 'Managing Director';
    }

    return rep.title;
  };

  const getFormattedAddress = () => {
    const addressPieces = [];

    if (rep.street) addressPieces.push(rep.street);
    if (rep.building) addressPieces.push(rep.building);

    addressPieces.push(`\n${rep.city}, ${rep.state}`);

    if (rep.zipLastFour) {
      addressPieces.push(`${rep.zip}-${rep.zipLastFour}`);
    } else {
      addressPieces.push(rep.zip);
    }

    return addressPieces.join(' ');
  };

  const linkClickedHandler = (event) => {
    if (!isEmpty(searchAnalytics)) {
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push({
        event: 'site search results clicked',
        'site-search': {
          term: searchAnalytics.searchTerm.toLowerCase(),
          'results-number': searchAnalytics.resultsNumber,
          'results-type': 'FR-card',
          'results-text': /\d/.test(event.currentTarget.innerText) ? 'phone number' : event.currentTarget.innerText.trim().toLowerCase(),
        },
      });
    }
  };

  const toggleMoreDetails = (event) => {
    setIsExpanded(!isExpanded);
    linkClickedHandler(event);
  };

  return (
    <DivAgentCardWrapperStyled
      className='vcard'
      id='nmx-dynamic-fr-card'>
      <Row {...isExpanded && { className: 'is-expanded' }}>
        <ColPhotoContainerStyled
          small={4}
          medium={3}
          nestedChild>
          <Row>
            <Col nested>
              { (resolveFrPhoto(rep)) ? (
                <ImgStyled
                  src={resolveFrPhoto(rep)}
                  photoWidth={photoWidth}
                  alt={rep.fullName}
                  className='fr-photo photo'
                />
              ) : (
                <Placeholder
                  first={rep.firstName}
                  last={rep.lastName} />
              )}
            </Col>
          </Row>
        </ColPhotoContainerStyled>
        <Col
          small={8}
          medium={9}
          nestedChild>
          <Row>
            <Col
              medium={6}
              nested
              styles={{ marginBottom: '1rem' }}>
              {/* TODO: parse out name for microformats 'given-name', 'family-name', etc */}
              <Typography
                className='fr-name n' // What is 'n'?
                component='h3'
                weight={300}
                variant={smallDown ? 'h1' : 'h3'}
                styles={{ paddingBottom: '1rem' }}
                themeType={themeType}>
                {/* TODO: this can be Typogrpahy span */}
                <span className='fn'>{rep.fullName}</span>
              </Typography>
              <Typography
                styles={{ letterSpacing: '1px' }}
                disableBottomPadding
                component='span'
                weight={300}
                themeType={themeType}>
                {getStandardizedTitle()}
              </Typography>
              <Ensemble
                agentnumber={rep.id}
                config={config} />
              {/* TODO: use List Component for this UL, add themeType */}
              <ul
                style={{
                  padding: '1rem 0',
                  margin: '0',
                  listStyle: 'none',
                }}>
                {rep.designations
                  && rep.designations.map((designation, index) => (
                    <li
                      key={`rep-designations-${index + 1}`}
                      style={{ display: 'inline' }}>
                      <span>
                        {designation}
                        {index < rep.designations.length - 1 && ', '}
                      </span>
                    </li>
                  ))}
              </ul>
              <Link
                linkUrl='https://brokercheck.finra.org/'
                isOutgoingLink
                onClick={linkClickedHandler}
                themeType={themeType}
                variant='primaryDeemphasize'>
                FINRA BrokerCheck
              </Link>
            </Col>
            <Col
              medium={6}
              nested>
              {/* TODO: formatted address needs some love, needs microformats */}
              <Typography
                component='address'
                variant='p'
                styles={{ whiteSpace: 'pre-wrap' }}
                themeType={themeType}>
                {getFormattedAddress()}
              </Typography>
              {rep.selectedPhone1 && rep.selectedPhone1.Number && (
                <Link
                  linkUrl={`tel:${sanitizePhone(rep.selectedPhone1.Number)}`}
                  themeType={themeType}
                  variant='primaryDeemphasize'>
                  {rep.selectedPhone1.Number}
                </Link>
              )}
              {/* if num1 and num2 exist, add delimiter */}
              {rep.selectedPhone1 && rep.selectedPhone1.Number && rep.selectedPhone2 && rep.selectedPhone2.Number && ' | '}
              {rep.selectedPhone2 && rep.selectedPhone2.Number && (
                <Link
                  linkUrl={`tel:${sanitizePhone(rep.selectedPhone2.Number)}`}
                  themeType={themeType}
                  variant='primaryDeemphasize'>
                  {rep.selectedPhone2.Number}
                </Link>
              )}
              {/* if num2 and num3 exist, add delimiter */}
              {rep.selectedPhone2 && rep.selectedPhone2.Number && rep.selectedPhone3 && rep.selectedPhone3.Number && ' | '}
              {/* if num1 and num3 exist, but num2 does not exist add delimiter */}
              {rep.selectedPhone1 && rep.selectedPhone1.Number && !rep.selectedPhone2 && rep.selectedPhone3 && rep.selectedPhone3.Number && ' | '}
              {rep.selectedPhone3 && rep.selectedPhone3.Number && (
                <Link
                  linkUrl={`tel:${sanitizePhone(rep.selectedPhone3.Number)}`}
                  themeType={themeType}
                  variant='primaryDeemphasize'>
                  {rep.selectedPhone3.Number}
                </Link>
              )}
              <ul
                className='fr-contact-links reduced'
                style={{
                  padding: '1rem 0',
                  margin: '0',
                  listStyle: 'none',
                }}>
                {rep.slug && (
                  <li className='fr-contact-link-container'>
                    <Link
                      linkUrl={`/financial/advisor/${rep.slug}/`}
                      ariaLabel={`Link to ${rep.fullName} profile page`}
                      className='fr-contact-link'
                      onClick={linkClickedHandler}
                      themeType={themeType}
                      variant='primaryDeemphasize'>
                      view my profile page
                    </Link>
                  </li>
                )}
              </ul>
              <Button
                className='fr-contact-cta nmx-button--secondary' // adding legacy classes here to handle active state
                id='fr-contact-details-cta'
                onClick={toggleMoreDetails}
                endAdornment={CaratRightIcon}
                endAdornmentStylesReset
                themeType={themeType}>
                Contact
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isExpanded && (
        <Row
          className='fr-contact'
          styles={{ marginTop: '2rem' }}>{/* TODO: Style these components instead of using inline styles */}
          <Col
            medium={5}
            nestedChild
            className='fr-contact-more-info'
            styles={{ paddingRight: '2rem' }}>
            {rep.briefBio && (
              <>
                {/* TODO: this can be Typography Components */}
                <h5>About My Practice</h5>
                <p>{rep.briefBio}</p>
              </>
            )}
            <Typography
              component='h5'
              uppercase
              disableBottomPadding
              weight={300}
              themeType={themeType}>
              Location
            </Typography>
            <Link
              linkUrl={`https://maps.google.com/maps?q=loc:${rep.latitude},${rep.longitude}`}
              isOutgoingLink
              themeType={themeType}
              variant='primaryDeemphasize'>
              {/* can add width 100% here to have the map maximized */}
              <picture>
                <source
                  srcSet={`https://maps.googleapis.com/maps/api/staticmap?center=${rep.latitude},${rep.longitude}&size=250x414&markers=${rep.latitude},${rep.longitude}&maptype=roadmap&zoom=13&key=${config.public.googlePlacesApiKey}`}
                  media={PHONE_MEDIA_QUERY}
                />
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${rep.latitude},${rep.longitude}&size=534x282&markers=${rep.latitude},${rep.longitude}&maptype=roadmap&zoom=13&key=${config.public.googlePlacesApiKey}`}
                  alt='Google Map'
                />
              </picture>
            </Link>
          </Col>
          <ColFormContainerStyled
            medium={7}
            className='fr-contact-service'>
            <AgentForm
              applicationId={applicationId}
              agentNumber={rep.id}
              config={config}
              customName={customName}
              isPcg={false}
              formLocation='search'
              campaign={campaign}
              source={source}
              topic={topic}
              themeType={themeType}
            />
          </ColFormContainerStyled>
        </Row>
      )}
    </DivAgentCardWrapperStyled>
  );
};

AgentCard.propTypes = {
  photoWidth: PropTypes.number,
  config: PropTypes.shape({
    public: PropTypes.shape({ googlePlacesApiKey: PropTypes.string }),
    services: PropTypes.shape({
      leadApiBaseUrl: PropTypes.string,
      serviceRequestApiBaseUrl: PropTypes.string,
    }),
  }),
  /** This callback is used to tell parent component that the form has first been engaged with */
  onFormEngaged: PropTypes.func,
  /** customName passed into AgentCard to override Default name */
  customName: PropTypes.string,
  searchAnalytics: PropTypes.shape({
    searchTerm: PropTypes.string,
    resultsNumber: PropTypes.string,
  }),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
  /** application Id for the form */
  applicationId: PropTypes.string,
};

AgentCard.defaultProps = {
  config: {
    public: {
      googlePlacesApiKey: '<%=googlePlacesApiKey%>',
      recaptchaInvisible: '<%=recaptchaInvisible%>',
    },
    services: {
      leadApiBaseUrl: '<%=leadApiBaseUrl%>',
      serviceRequestApiBaseUrl: '<%=serviceRequestApiBaseUrl%>',
    },
    searchAnalytics: {},
  },
};

export default AgentCard;
