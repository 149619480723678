import React from 'react';
import PropTypes from 'prop-types';

import {
  BaseLink,
  StartAdornmentContainerStyled,
  EndAdornmentContainerStyled,
} from './styles';

export const LinkComponent = React.forwardRef(
  (
    {
      ariaLabel,
      branding,
      children,
      className,
      config,
      endAdornment: RenderEndAdornment,
      endAdornmentStylesReset,
      id,
      isInternalPdf,
      isOutgoingLink,
      linkUrl,
      noWrap,
      resetBaseStyles,
      startAdornment: RenderStartAdornment,
      startAdornmentStylesReset,
      themeType,
      variant,
      ...rest
    },
    ref,
  ) => (
    <BaseLink
      {...(ariaLabel && { 'aria-label': ariaLabel })} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
      branding={branding}
      className={className}
      config={config}
      href={linkUrl}
      id={id}
      noWrap={noWrap}
      ref={ref}
      resetBaseStyles={resetBaseStyles}
      themeType={themeType}
      variant={variant}
      {...(isInternalPdf && {
        target: '_blank',
        rel: 'nofollow noopener',
      })}
      {...(isOutgoingLink && {
        target: '_blank',
        rel: 'nofollow noopener noreferrer',
      })}
      {...rest}>
      {RenderStartAdornment && (
        <StartAdornmentContainerStyled
          startAdornmentStylesReset={startAdornmentStylesReset}>
          <RenderStartAdornment />
        </StartAdornmentContainerStyled>
      )}
      {children}
      {RenderEndAdornment && (
        <EndAdornmentContainerStyled
          endAdornmentStylesReset={endAdornmentStylesReset}>
          <RenderEndAdornment />
        </EndAdornmentContainerStyled>
      )}
    </BaseLink>
  ),
);

LinkComponent.propTypes = {
  /** optional ariaLabel attribute */
  ariaLabel: PropTypes.string,
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** required inner text */
  children: PropTypes.node.isRequired,
  /** optional additional className */
  className: PropTypes.string,
  /** allows for CSS asset path in styles */
  config: PropTypes.shape({
    // TODO: where is this used?
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  /** optional icon component that displays at the end of the link */
  endAdornment: PropTypes.func,
  /** optional boolean to reset default icon component styles  */
  endAdornmentStylesReset: PropTypes.bool,
  /** optional id attribute */
  id: PropTypes.string,
  /** optional boolean to automatically add outgoing link attributes: target="_blank" rel="noopener nofollow" */
  isInternalPdf: PropTypes.bool,
  /** optional boolean to automatically add outgoing link attributes: target="_blank" rel="noopener nofollow noreferrer" */
  isOutgoingLink: PropTypes.bool, // TODO: convert href to url (used in DynamicCallout, ArticleCard, DocumentLink, etc)?
  /** required url path for href */
  linkUrl: PropTypes.string.isRequired,
  /** optional boolean to avoid link widows */
  noWrap: PropTypes.bool,
  /** optional boolean to reset visible anchor link styles, but gives developer ability to use other Aura Link features */
  resetBaseStyles: PropTypes.bool,
  /** optional icon component that displays at the start of the link */
  startAdornment: PropTypes.func,
  /** optional boolean to reset default icon component styles  */
  startAdornmentStylesReset: PropTypes.bool,
  /** optional theme */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** required variant */
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primaryDeemphasize',
    'buttonLinkPrimary',
    'buttonLinkSecondary',
    'buttonLinkTertiary',
  ]),
};

LinkComponent.defaultProps = {
  branding: 'nm',
  children: '',
  config: {
    basePath: '<%=basePath%>',
    nmxTemplateVersion: '<%=nmxTemplateVersion%>',
  },
  isInternalPdf: false,
  isOutgoingLink: false,
  noWrap: false,
  resetBaseStyles: false,
  themeType: 'lightTheme',
  variant: 'primary',
};

export default LinkComponent;
