import React from 'react';
import PropTypes from 'prop-types';
// components
import Link from '../../components/Link';
// icons
import YoutubeIcon from '../../foundations/Icon/icons/social/Youtube';
// styles
import {
  ColStyledIconContainer,
  ColStyledImageContainer,
  ColStyledLinkTextContainer,
  DivStyledContainer,
  DivStyledImageContainer,
  RowStyled,
  TypographyStyled,
} from './styles';

export const DocumentLinkComponent = ({
  children,
  className,
  iconObject: RenderIcon,
  imageUrl,
  imageVariant,
  isInternalPdf,
  isOutgoingLink,
  linkAriaLabel,
  linkId,
  linkUrl,
}) => (
  <DivStyledContainer className={className}>
    <Link
      id={linkId}
      ariaLabel={linkAriaLabel}
      isInternalPdf={isInternalPdf}
      isOutgoingLink={isOutgoingLink}
      linkUrl={linkUrl}
      variant='primaryDeemphasize'>
      <RowStyled>
        {imageVariant === 'icon' && (
          <ColStyledIconContainer
            xsmall={4}
            large={3}>
            <RenderIcon />
          </ColStyledIconContainer>
        )}
        {imageVariant === 'image' && (
          <ColStyledImageContainer xsmall={4}>
            <DivStyledImageContainer
              id={`${linkId}-background-image-container`}
              backgroundImage={imageUrl} />
          </ColStyledImageContainer>
        )}
        <ColStyledLinkTextContainer xsmall={8}>
          <TypographyStyled>
            {children}
          </TypographyStyled>
        </ColStyledLinkTextContainer>
      </RowStyled>
    </Link>
  </DivStyledContainer>
);

DocumentLinkComponent.propTypes = {
  /** required description text */
  children: PropTypes.node.isRequired,
  /** optional additional className */
  className: PropTypes.string,
  /** select between the imageVariant types */
  imageVariant: PropTypes.oneOf(['icon', 'image']),
  /** coincides with imageVariant='icon', iconObject must be supplied */
  iconObject: PropTypes.any,
  /** coincides with imageVariant='image', imageUrl must be supplied */
  imageUrl: PropTypes.any,
  /** optional isInternalPdf boolean */
  isInternalPdf: PropTypes.bool,
  /** optional isOutgoingLink boolean */
  isOutgoingLink: PropTypes.bool,
  /** optional aria-label of link */
  linkAriaLabel: PropTypes.any,
  /** required id of the link */
  linkId: PropTypes.string.isRequired,
  /** required URL of link */
  linkUrl: PropTypes.string.isRequired,
};

DocumentLinkComponent.defaultProps = {
  iconObject: YoutubeIcon,
  imageVariant: 'icon',
};

export default DocumentLinkComponent;
