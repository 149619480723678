// deprecated
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// utils
import Location from '@nmx/utils/dist/utilities/frontend/location';
import resolveFrPhoto from '../../../assets/js/utils/resolveFrPhoto.util';
import { processAgentId } from '../../utils/processAgentId';
// components
import CrcCard from '../DynamicAgentCard/CrcCard';
import DefaultCard from './DefaultCard';
import EntityCard from './EntityCard';
import SectionContainer from '../../components/SectionContainer';

const AdvisorConnectComponent = ({
  agentObject,
  backgroundVariant,
  checkCrc,
  className,
  config,
  defaultCardProps,
  disableLazyLoad,
  removeDefaultCardForLeads,
  sectionId,
  themeType,
}) => {
  const [isEnsemble, setIsEnsemble] = useState(false);
  // if agentObject is passed in, it takes precedence over everything
  const [agentData, setAgentData] = useState(agentObject); // TODO: this just all seems redundant
  const [ensembleData, setEnsembleData] = useState();
  const [crcData, setCrcData] = useState({});
  const [isCrcDataValid, setIsCrcDataValid] = useState(false);

  const getStandardizedTitle = (agentObj) => {
    if (agentObj.type === 'MD') {
      return 'Managing Director';
    }

    return agentObj.title;
  };

  const transformAgentData = (agentObj) => ({
    address: {
      street: agentObj.street,
      building: agentObj.building,
      city: agentObj.city,
      state: agentObj.state,
      zip: agentObj.zip,
      zipLastFour: agentObj.zipLastFour,
    },
    firstName: agentObj.firstName,
    imgSrc: resolveFrPhoto(agentObj),
    lastName: agentObj.lastName,
    linkUrl: `${agentObj.url}/`,
    name: agentObj.fullName,
    phone: agentObj.selectedPhone1 && agentObj.selectedPhone1.Number,
    slug: `/financial/advisor/${agentObj.slug}/`,
    title: getStandardizedTitle(agentObj),
  });

  const transformEnsembleData = (ensembleObj) => ({
    address: {
      street: ensembleObj.street,
      building: ensembleObj.building,
      city: ensembleObj.city,
      state: ensembleObj.state,
      zip: ensembleObj.zip,
      zipLastFour: ensembleObj.zipLastFour,
    },
    name: ensembleObj.name,
    phone: ensembleObj.primaryPhone,
    slug: ensembleObj.url,
    imgSrc: ensembleObj.imageUrl || '/life-and-money/assets/images/ensemble-default.jpg',
    linkUrl: ensembleObj.url,
  });
  const setCRCValues = () => {
    // Check for CRC value in query string
    const crcQueryParam = (Location.getQueryParam('crc') || '').substring(0, 4);
    if (crcQueryParam === 'true' || crcQueryParam === 'True') {
      const crc = true;
      const phone = Location.getQueryParam('no_phone');
      const city = Location.getQueryParam('no_city');

      setCrcData({
        crc,
        phone,
        city,
      });

      setIsCrcDataValid(crc && phone && city);
    }
  };

  const defaultCardNeedsToBeRemoved = () => {
    if (typeof localStorage !== 'undefined') {
      const localStorageVar = localStorage.getItem('leadConfirmedUser');
      const queryParam = (Location.getQueryParam('cta') || '').substring(0, 4);

      if (queryParam === 'none' || localStorageVar === 'true') {
        // If the user is a lead, set the localStorage var
        localStorage.setItem('leadConfirmedUser', true);

        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    processAgentId(config, agentObject, setIsEnsemble, setEnsembleData, setAgentData);
    setCRCValues();
  }, []);

  if (isEnsemble && ensembleData) { // TODO: these cards can probably be combined or better yet, different variants
    return (
      <SectionContainer
        backgroundVariant={backgroundVariant}
        className={className}
        cardType='ensemble'
        id={sectionId}
        moduleName='FR-card-fafa-module'
        moduleVariation='A'
        themeType={themeType}>
        <EntityCard
          config={config}
          backgroundVariant={backgroundVariant}
          dataObject={transformEnsembleData(ensembleData)}
          themeType={themeType} />
      </SectionContainer>
    );
  }

  // // Crcs are orphans, they don't have an agent anymore. So show the city and office phone number instead...
  if (checkCrc && isCrcDataValid) {
    return (
      <CrcCard
        city={crcData.city}
        phone={crcData.phone} />
    );
  }

  if (agentData) {
    return (
      <SectionContainer
        backgroundVariant={backgroundVariant}
        className={className}
        cardType='agent'
        id={sectionId}
        moduleName='FR-card-fafa-module'
        moduleVariation='A'
        themeType={themeType}>
        <EntityCard
          backgroundVariant={backgroundVariant}
          config={config}
          dataObject={transformAgentData(agentData)}
          themeType={themeType} />
      </SectionContainer>
    );
  }

  // /*
  //   we're about to show the DefaultCard, but HOLLLLLL UP.
  //   We may need this entire section to disappear.
  //   If business wants us to hide this for visitors meets certain criteria
  // */
  if (removeDefaultCardForLeads && defaultCardNeedsToBeRemoved()) {
    return false;
  }

  return (
    <SectionContainer
      backgroundVariant={backgroundVariant}
      className={className}
      cardType='default'
      id={sectionId}
      moduleName='FR-card-fafa-module'
      moduleVariation='A'
      themeType={themeType}>
      <DefaultCard
        backgroundVariant={backgroundVariant}
        cta={defaultCardProps.cta}
        desktopWebpSrc={defaultCardProps.desktopWebpSrc}
        disableLazyLoad={disableLazyLoad}
        headingText={defaultCardProps.headingText}
        imageOnTop={defaultCardProps.imageOnTop}
        imageSrc={defaultCardProps.imageSrc}
        imageVariant={defaultCardProps.imageVariant}
        mobileImageSrc={defaultCardProps.mobileImageSrc}
        mobileWebpSrc={defaultCardProps.mobileWebpSrc}
        themeType={themeType}
        subText={defaultCardProps.subText}
        text={defaultCardProps.text}
      />
    </SectionContainer>
  );
};

AdvisorConnectComponent.propTypes = {
  agentObject: PropTypes.shape({
    id: PropTypes.string,
    agentNumber: PropTypes.string,
    fullName: PropTypes.string,
    type: PropTypes.string, // MP, MD, etc
    street: PropTypes.string,
    building: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    zipLastFour: PropTypes.string,
    designations: PropTypes.array,
    selectedPhone1: PropTypes.object,
    briefBio: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    photo: PropTypes.string,
    gender: PropTypes.string,
  }),
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  checkCrc: PropTypes.bool,
  /** Optional custom class name */
  className: PropTypes.string,
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  /** optional defaultCardProps */
  defaultCardProps: PropTypes.shape({
    headingText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    desktopWebpSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    imageSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    imageVariant: PropTypes.oneOf(['rectangular', 'round']),
    mobileImageSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    mobileWebpSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    cta: PropTypes.shape({
      linkText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
      linkUrl: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
    }),
    subText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  disableLazyLoad: PropTypes.bool,
  removeDefaultCardForLeads: PropTypes.bool,
  /** Id for the section */
  sectionId: PropTypes.string.isRequired,
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']), // TODO: add nmx-pcg
};

AdvisorConnectComponent.defaultProps = {
  checkCrc: false,
  defaultCardProps: {},
  disableLazyLoad: false,
  removeDefaultCardForLeads: false,
};

export default AdvisorConnectComponent;
