import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, events } from '@gelato/analytics-datalayer';
import { serviceRequestFactory } from '@nmx/utils/dist/services/ServiceRequestService/Frontend';
import { leadServiceFactory } from '@nmx/utils/dist/services/LeadService/Frontend';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import { newRelicJSError } from '@nmx/utils/dist/utilities/frontend/Analytics/new_relic_helper';
// utils
import {
  buildLeadPayload,
  buildServiceRequestPayload,
  goals,
  incomes,
  validateAge,
  validateComments,
  validateEmail,
  validateFirstName,
  validateGoal,
  validateIncomeRange,
  validateLastName,
  validatePhone,
  validatePolicyOwnerSelected,
  validateWealthRange,
  validateZipCode,
} from './utilities';
// components
import { Col, Row } from '../../foundations/Grid';
import Button from '../../components/Button';
import Form from '../../components/forms/Form';
import FormRow from '../../components/forms/FormRow';
import RadioGroup from '../../components/forms/RadioGroup';
import Select from '../../components/forms/Select';
import SubmissionError from '../forms/SubmissionError';
import SubmissionSuccess from '../forms/SubmissionSuccess';
import TextField from '../../components/forms/TextField';
import Typography from '../../foundations/Typography';
// recaptcha
import RecaptchaInFormBadge from './RecaptchaInFormBadge';
// styles
import {
  ColStyled,
  FormRowStyled,
} from './styles';

export const AgentFormComponent = (props) => {
  const {
    applicationId,
    config,
    customName,
    formLocation,
    id,
    onFormEngaged,
    themeType,
  } = props;

  // form states
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrored, setFormErrored] = useState(false);

  // form value fields
  const [values, setValues] = React.useState({
    application_id: '',
    age: '',
    campaign_name: props.campaign,
    comments: '',
    email: '',
    first_name: '',
    goal: '',
    income_range: '',
    last_name: '',
    phone: '',
    policy_owner: 'No', // setting No as the default value
    wealth_range: '',
    zip: '',
  });
  // form error states
  const [errors, setErrors] = useState({
    age: false,
    comments: false,
    email: false,
    first_name: false,
    goal: false,
    income_range: false,
    last_name: false,
    phone: false,
    policy_owner: false,
    wealth_range: false,
    zip: false,
  });

  // adobe variables
  const {
    FORM_COMPLETE_FAILURE,
    FORM_COMPLETE,
    FORM_INTERACT,
    FORM_LOAD,
  } = CONSTANTS.NAME_PUSH_EVENTS;

  const { GENERAL } = CONSTANTS.NAME_LEAD_FORM_EXPERIENCES;

  const {
    SERVICE_REQUEST,
    LEAD,
  } = CONSTANTS.NAME_LEAD_FORM_SUBMIT_TYPE;

  // when component mounts
  useEffect(() => {
    // this is to set the initial state
    setValues({
      ...values,
      application_id: applicationId,
    });
    if (Location.getQueryParam('campName')) {
      setValues({
        ...values,
        campaign_name: Location.getQueryParam('campName'),
      });
    }
    if (props.isPcg) {
      // pcg profile default to working with fr state
      setValues({
        ...values,
        policy_owner: 'Yes',
      });
    }

    events.leadForms.load({
      experience: GENERAL,
      eventName: FORM_LOAD,
      customName,
    });
  }, []);

  const logError = (message, error) => {
    newRelicJSError(message, error);
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    // Inform parent component of engagement with form
    onFormEngaged();
  };

  const handleSubmitError = (error, isLeadCall) => {
    setFormErrored(true);

    try {
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
      // if recaptcha execute fails, show error
      logError(
        `Error submitting ${
          isLeadCall
            ? 'lead'
            : 'service request'
        } on ${formLocation}.`,
        error,
      );
    } catch (recaptchaError) {
      logError(`Error resetting recaptcha on ${formLocation}.`, recaptchaError);
    }
  };

  const handleReCaptchaCallback = (recaptchaReponseToken) => {
    let request = null;
    let payload = null;
    let isLeadCall = false;
    if (values.policy_owner === 'Yes') {
      payload = buildServiceRequestPayload(
        props.agentNumber,
        recaptchaReponseToken,
        props.source,
        props.topic,
        values,
      );
      payload.is_recaptcha_v3 = true;

      const ServiceRequestService = serviceRequestFactory({ baseURL: props.config.services.serviceRequestApiBaseUrl });

      request = ServiceRequestService.submitServiceRequest(payload);
    } else {
      payload = buildLeadPayload(
        props.agentNumber,
        recaptchaReponseToken,
        props.source,
        props.topic,
        values,
      );
      payload.is_recaptcha_v3 = true;

      const LeadService = leadServiceFactory({ baseURL: props.config.services.leadApiBaseUrl });
      request = LeadService.submitLead(payload);
      isLeadCall = true;
    }

    try {
      request
        .then(() => {
          setFormSubmitted(true);
          events.leadForms.complete({
            experience: GENERAL,
            submissionType: isLeadCall ? LEAD : SERVICE_REQUEST,
            eventName: FORM_COMPLETE,
            submissionData: payload,
          });
        })
        .catch((error) => {
          handleSubmitError(error, isLeadCall);
        });
    } catch (error) {
      handleSubmitError(error, isLeadCall);
      events.leadForms.completeFailure({
        experience: GENERAL,
        errorMsg: `Unable to submit agent form with error ${error}`,
        eventName: FORM_COMPLETE_FAILURE,
      });
    }
  };

  // call this function when we've validated the form and are ready to submit
  const executeRecaptchaAndSubmit = () => {
    if (window.grecaptcha) {
      try {
        // recaptchaV3 method for executing
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(config.public.recaptchaV3, { action: 'submit' }).then((token) => {
            document
              .querySelectorAll('.recaptchaResponse')
              // eslint-disable-next-line no-param-reassign
              .forEach((elem) => { elem.value = token; });
            handleReCaptchaCallback(token);
          });
        });
      } catch (error) {
        // if recaptcha execute fails, show error
        logError(`Error executing recaptcha on ${formLocation}.`, error);
        setFormErrored(true);
      }
    }
  };

  // call this function when we've validated the form and there's an error
  const resetRecaptcha = () => {
    try {
      // recaptchaV3 method for executing
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(config.public.recaptchaV3, { action: 'reset' })
          .then((token) => {
            document
              .querySelectorAll('.recaptchaResponse')
              // eslint-disable-next-line no-param-reassign
              .forEach((elem) => { elem.value = token; });
          });
      });
    } catch (error) {
      // if recaptcha execute fails, show error
      logError(`Error resetting recaptcha on ${formLocation}.`, error);
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (event) => {
    setIsSubmitting(true);

    event.preventDefault();
    event.stopPropagation();

    const errorCheck = {
      policy_owner: !validatePolicyOwnerSelected(values.policy_owner),
      first_name: !validateFirstName(values.first_name),
      last_name: !validateLastName(values.last_name),
      phone: !validatePhone(values.phone),
      email: !validateEmail(values.email),
      age: !validateAge(values.age, values.policy_owner),
      zip: !validateZipCode(values.zip),
      goal: !validateGoal(values.goal, values.policy_owner),
      income_range: !validateIncomeRange(
        values.income_range,
        values.policy_owner,
      ),
      wealth_range: !validateWealthRange(
        values.wealth_range,
        values.income_range,
        values.policy_owner,
      ),
      comments: !validateComments(values.comments, values.policy_owner),
    };

    setErrors(errorCheck);
    const isValid
      = !errorCheck.policy_owner
      && !errorCheck.first_name
      && !errorCheck.last_name
      && !errorCheck.phone
      && !errorCheck.email
      && !errorCheck.age
      && !errorCheck.zip
      && !errorCheck.goal
      && !errorCheck.income_range
      && !errorCheck.wealth_range
      && !errorCheck.comments;

    if (isValid) {
      executeRecaptchaAndSubmit();
    } else {
      resetRecaptcha();
      setIsSubmitting(false);
    }
  };

  const handleAdobeDirectCall = (event) => {
    const { name, value } = event.target;

    // return early if value is empty, Xai asked us not to send if the value is empty
    if (!value) return;
    events.leadForms.interact({
      experience: GENERAL,
      actionType: name,
      actionValues: value,
      eventName: FORM_INTERACT,
    });
  };

  if (formSubmitted) {
    return (
      <SubmissionSuccess
        descriptionText={
          values.policy_owner === 'Yes'
            ? 'Your financial advisor will get back to you soon.'
            : null
        }
      />
    );
  }

  if (formErrored) {
    return <SubmissionError />;
  }

  const options = [{
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
    selected: true,
  }];

  return (
    <>
      <Form
        {...applicationId && { application_id: applicationId }}
        legend='Northwestern Mutual Agent Form'
        id={id}
        onSubmit={handleSubmit}>
        <FormRowStyled>
          <Col>
            <RadioGroup
              disabled={isSubmitting}
              hasError={errors.policy_owner}
              helperText='Please select if you are currently working with this advisor.'
              id='nm-agent-form-policy-owner'
              labelText='Are you currently working together?'
              name='policy_owner'
              onChange={(e) => {
                handleChange(e);
                handleAdobeDirectCall(e);
              }}
              options={options}
              themeType={themeType}
              variant='visualPicker'
            />
          </Col>
        </FormRowStyled>
        <FormRow>
          <Col>
            <Typography disableBottomPadding>
              Answer some questions so I&#39;ll have a better idea of how I can help you.
            </Typography>
          </Col>
        </FormRow>
        <Row>
          <ColStyled small={6}>
            <TextField
              disabled={isSubmitting}
              hasError={errors.first_name}
              helperText='Please enter a valid first name.'
              id='nm-agent-form-first-name'
              labelText='First Name'
              maxLength={150}
              name='first_name'
              onBlur={handleAdobeDirectCall}
              onChange={handleChange}
              required
              themeType={themeType}
              value={values.first_name}
            />
          </ColStyled>
          <ColStyled small={6}>
            <TextField
              disabled={isSubmitting}
              hasError={errors.last_name}
              helperText='Please enter a valid last name.'
              id='nm-agent-form-last-name'
              labelText='Last Name'
              maxLength={150}
              name='last_name'
              onBlur={handleAdobeDirectCall}
              onChange={handleChange}
              required
              themeType={themeType}
              value={values.last_name}
            />
          </ColStyled>
          <ColStyled small={6}>
            <TextField
              disabled={isSubmitting}
              hasError={errors.zip}
              helperText='Please enter a valid zip code.'
              id='nm-agent-form-zip'
              labelText='Zip Code'
              maxLength={5}
              name='zip'
              onBlur={handleAdobeDirectCall}
              onChange={handleChange}
              required
              themeType={themeType}
              value={values.zip}
            />
          </ColStyled>
          <ColStyled small={6}>
            <TextField
              disabled={isSubmitting}
              hasError={errors.phone}
              helperText='Please enter a valid phone number.'
              id='nm-agent-form-phone'
              labelText='Phone Number'
              maxLength={14}
              name='phone'
              onBlur={handleAdobeDirectCall}
              onChange={handleChange}
              required
              themeType={themeType}
              value={values.phone}
            />
          </ColStyled>
          <ColStyled>
            <TextField
              disabled={isSubmitting}
              hasError={errors.email}
              helperText='Please enter a valid email address.'
              id='nm-agent-form-email'
              labelText='Email'
              maxLength={150}
              name='email'
              onBlur={handleAdobeDirectCall}
              onChange={handleChange}
              required
              themeType={themeType}
              value={values.email}
            />
          </ColStyled>

          {/* Lead Fields */
            values.policy_owner !== 'Yes' && (
              <ColStyled small={6}>
                <TextField
                  disabled={isSubmitting}
                  hasError={errors.age}
                  helperText='Please enter a valid age.'
                  id='nm-lead-form-age'
                  labelText='Age'
                  max='999'
                  name='age'
                  onBlur={handleAdobeDirectCall} // TODO: make these all the same - some of these calls are passing in event, needed?
                  onChange={handleChange}
                  required
                  themeType={themeType}
                  type='number'
                  value={values.age}
                />
              </ColStyled>
            )
          }
          {/* Lead Fields */
            values.policy_owner !== 'Yes' && (
              <ColStyled small={6}>
                <Select
                  disabled={isSubmitting}
                  hasError={errors.goal}
                  helperText='Please select a goal'
                  id='nm-lead-form-goal'
                  labelText='My biggest financial goal is to:'
                  name='goal'
                  onBlur={(e) => {
                    handleChange(e);
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    handleAdobeDirectCall(e);
                  }}
                  options={goals}
                  required
                  themeType={themeType}
                  value={values.goal}
                />
              </ColStyled>
            )
          }
          {/* Lead Fields */
            values.policy_owner !== 'Yes' && (
              <ColStyled small={6}>
                <Select
                  disabled={isSubmitting}
                  hasError={errors.income_range}
                  helperText='Please select an income range.'
                  id='nm-lead-form-income-range'
                  labelText='Income Range:'
                  name='income_range'
                  onBlur={(e) => {
                    handleChange(e);
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    handleAdobeDirectCall(e);
                  }}
                  options={incomes}
                  required
                  themeType={themeType}
                  value={values.income_range}
                />
              </ColStyled>
            )
          }
          {/* Lead Fields */
            values.policy_owner !== 'Yes' && values.income_range === '39999' && (
              <ColStyled small={6}>
                <Select
                  disabled={isSubmitting}
                  hasError={errors.wealth_range}
                  helperText='Please select a wealth range.'
                  id='nm-lead-form-wealth-range'
                  labelText='My other assets add up to:'
                  name='wealth_range'
                  onBlur={(e) => {
                    handleChange(e);
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    handleAdobeDirectCall(e);
                  }}
                  options={incomes}
                  required
                  themeType={themeType}
                  value={values.wealth_range}
                />
              </ColStyled>
            )
          }
          {/* Service Request Fields */
            values.policy_owner === 'Yes' && (
              <ColStyled>
                <TextField
                  disabled={isSubmitting}
                  hasError={errors.comments}
                  helperText='Please enter comments.'
                  id='nm-service-request-comments'
                  labelText='Tell us more about your needs...'
                  maxLength={2500} // TODO: is this really the max number?
                  multiline
                  name='comments'
                  onBlur={(e) => {
                    handleAdobeDirectCall(e);
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                  textAreaRows={5}
                  themeType={themeType}
                  {...values.comments && { value: values.comments }}
                ></TextField>
              </ColStyled>
            )
          }
          <Col medium={6}>
            <RecaptchaInFormBadge />
          </Col>
          <Col>
            <Button
              className='nmx-button--secondary' // adding legacy classes here to handle active state
              disabled={isSubmitting}
              id='profile-pages-nm-agent-form-submit-button'
              themeType={themeType}
              type='submit'
              value='submit'>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      <input
        type='hidden'
        name='recaptcha_response'
        className='recaptchaResponse' />
    </>
  );
};

AgentFormComponent.propTypes = {
  /** application Id for the form */
  applicationId: PropTypes.string,
  agentNumber: PropTypes.string.isRequired,
  campaign: PropTypes.string.isRequired,
  /** customName is used for analytics to determine where the form submission is coming from */
  customName: PropTypes.string,
  formLocation: PropTypes.string.isRequired,
  id: PropTypes.string,
  isPcg: PropTypes.bool,
  /** This callback is used to tell parent component that the form has first been engaged with */
  onFormEngaged: PropTypes.func,
  recaptchaInvisible: PropTypes.string,
  source: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

AgentFormComponent.defaultProps = {
  applicationId: 'ADVISOR-SITE', // set it to be ADVISOR-SITE by default, and can be overrided
  id: 'nm-agent-form',
  isPcg: false,
  onFormEngaged: () => {},
  config: { public: { recaptchaInvisible: '<%=recaptchaInvisible%>' } },
};

export default AgentFormComponent;
