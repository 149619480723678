import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col } from '../../foundations/Grid';
import gradients from '../../foundations/theme/effects/gradients';
import Typography from '../../foundations/Typography';

// style getters
import {
  getSectionBackgroundColor,
  getSectionColor,
} from '../../components/SectionContainer/styles';

const getCredentialCardContainerStyles = (
  backgroundVariant,
  branding,
  theme,
  themeType,
) => {
  const smallUp = theme.smallUp();
  const mediumUp = theme.mediumUp();
  let thisMargin = '1rem 0';
  if (mediumUp) {
    thisMargin = '1rem 1.25rem'; // TODO: confirm with Creative, blockquote vs div should be the same // TODO: create a blockquote "reset" similar to list?
  } else if (smallUp) {
    thisMargin = '1rem 1.25rem 0';
  }
  return css`
    margin: ${thisMargin};
    background-color: ${getSectionBackgroundColor(
    backgroundVariant,
    branding,
    theme,
    themeType,
  )};
    .nmx-credential-logo {
      height: 100%;
      max-height: ${rem(20)};
      &__ibd {
        max-height: ${rem(14)};
      }
    }
    .nmx-block {
      /** used for .nmx-block text in Moody's AMBest, etc */
      color: ${getSectionColor(theme, themeType)};
    }
  `;
};

export const CredentialCardContainerDivStyled = styled.div`
  ${({ backgroundVariant, branding, theme, themeType }) => getCredentialCardContainerStyles(
    backgroundVariant,
    branding,
    theme,
    themeType,
  )}
`;

export const CredentialCardContainerBlockquoteStyled = styled.blockquote`
  ${({ backgroundVariant, branding, theme, themeType }) => getCredentialCardContainerStyles(
    backgroundVariant,
    branding,
    theme,
    themeType,
  )}
`;

export const CredentialCardColStyled = styled(Col)`
  ${({ theme }) => {
    const mediumDown = theme.mediumDown();
    return css`
      ${mediumDown && `padding-bottom: ${rem(20)}`};
    `;
  }}
`;

export const CredentialHeadingTextStyled = styled(Typography)`
  ${({
    accentColorVariant,
    accentOrientation,
    branding,
    hasAccent,
    headingColorVariant,
    headingTextUpperCase,
    theme,
    themeType,
  }) => {
    let thisColor = theme.colors.blue.blue;
    if (themeType === 'darkTheme') {
      if (headingColorVariant === 'sky') {
        thisColor = theme.colors.sky.sky;
      } else {
        thisColor = theme.colors.text.darkTheme.default;
      }
    }

    let thisAccentColor = theme.colors.gold.gold;
    if (branding === 'pcg') {
      thisAccentColor = theme.colors.brand.pcg.gold1;
    } else if (accentColorVariant === 'sky') {
      thisAccentColor = theme.colors.sky.sky;
    }
    let thisTopMargin = 0;
    if (branding === 'pcg') {
      thisTopMargin = rem(16);
    }

    // hasAccent logic

    return css`
      margin-bottom: ${rem(6)};
      font-size: ${theme.mediumUp() ? rem(64) : rem(60)};
      line-height: ${rem(60)};
      color: ${thisColor};
      text-transform: ${headingTextUpperCase ? 'uppercase' : 'none'};
      && {
        overflow: visible; /** for wide headings */ /** TODO: double-specificity needed? */
      }
      ${hasAccent
        && accentOrientation === 'vertical'
        && css`
          ::before {
            content: '';
            display: inline-block;
            margin-right: ${rem(16)};
            width: ${rem(3)};
            height: ${rem(44)};
            background-color: ${thisAccentColor};
          }
        `}
      ${hasAccent
        && accentOrientation === 'horizontal'
        && css`
          text-align: center;
          ::after {
            content: '';
            display: block;
            width: ${rem(60)};
            ${branding === 'pcg' && `height: ${rem(5)};`};
            margin: ${thisTopMargin} auto ${rem(16)};
            ${branding === 'nm' && `padding-bottom: ${rem(16)};`};
            ${branding === 'nm'
              && `border-bottom: ${rem(4)} solid ${thisAccentColor};`};
            ${branding === 'pcg' && `${gradients.pcgGold}`}
          }
        `}
      overflow: hidden;
    `;
  }}
`;

export const DescriptionTextStyled = styled(Typography)`
  ${({ credentialLogo }) => css`
    ${!credentialLogo
      && css`
        padding-bottom: 0; /** remove extra padding here that is causing visually-apparent extra section padding */
      `}
  `}
`;

export const CredentialFooterText = styled.footer`
  display: none;
`;
