import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
// styles
import {
  ColStyled,
  ContainerStyled,
  DivStyledBackgroundContainer,
  LinkStyledButton,
  RowStyled,
  SectionStyled,
  TypographyStyledDescription,
  TypographyStyledHeading,
  TypographyStyledProductName,
} from './styles';

export const HeroSectionComponent = ({
  backgroundImageAriaLabel,
  backgroundImageDesktop,
  backgroundImageDesktopLowRes,
  backgroundImageDesktopWebp,
  backgroundImageMobile,
  backgroundImageMobileLowRes,
  backgroundImageMobileWebp,
  backgroundImageTablet,
  backgroundImageTabletLowRes,
  backgroundImageTabletWebp,
  backgroundVariant,
  branding,
  className,
  ctaPrimary,
  ctaSecondary,
  descriptionText,
  headingText,
  productName,
  sectionId,
  themeType,
}) => {
  const theme = useContext(ThemeContext);
  const largeUp = theme.largeUp();

  const getBackgroundImageContainer = (
    <DivStyledBackgroundContainer
      id={`background-image-${sectionId}`}
      bgImageLargeUpDefault={backgroundImageDesktop}
      bgImageLargeUpLowRes={backgroundImageDesktopLowRes}
      bgImageLargeUpWebp={backgroundImageDesktopWebp}
      bgImageXSmallUpDefault={backgroundImageMobile}
      bgImageXSmallUpLowRes={backgroundImageMobileLowRes}
      bgImageXSmallUpWebp={backgroundImageMobileWebp}
      bgImageMediumUpDefault={backgroundImageTablet}
      bgImageMediumUpLowRes={backgroundImageTabletLowRes}
      bgImageMediumUpWebp={backgroundImageTabletWebp}>
      <span
        role='img'
        aria-label={backgroundImageAriaLabel} />
    </DivStyledBackgroundContainer>
  );

  return (
    <SectionStyled
      id={`section-${sectionId}`}
      className={className}
      role='banner'
      data-module={`section-${sectionId}:hero-module:A`}>
      {!largeUp && (
        getBackgroundImageContainer
      )}
      <ContainerStyled>
        <RowStyled>
          <ColStyled
            medium={6}
            backgroundVariant={backgroundVariant}
            branding={branding}
            themeType={themeType}>
            <>
              <TypographyStyledProductName
                id={`hero-product-name-${sectionId}`}
                component='h1'
                themeType={themeType}
                variant='p'>
                {productName}
              </TypographyStyledProductName>
              <TypographyStyledHeading
                id={`hero-heading-text-${sectionId}`}
                align='left'
                component='h2'
                themeType={themeType}
                variant='h1'>
                {headingText}
              </TypographyStyledHeading>
              <TypographyStyledDescription
                id={`hero-description-text-${sectionId}`}
                align='left'
                themeType={themeType}>
                {descriptionText}
              </TypographyStyledDescription>
              {ctaPrimary && (
                <LinkStyledButton
                  id={`${sectionId}-cta-primary`}
                  ariaLabel={ctaPrimary.ariaLabel}
                  linkUrl={ctaPrimary.linkUrl}
                  themeType={themeType}
                  variant='buttonLinkPrimary'
                  isInternalPdf={ctaPrimary.isInternalPdf && !ctaPrimary.isOutgoingLink}
                  isOutgoingLink={ctaPrimary.isOutgoingLink && !ctaPrimary.isInternalPdf}
                >
                  {ctaPrimary.buttonText}
                </LinkStyledButton>
              )}
              {ctaSecondary && (
                <LinkStyledButton
                  id={`${sectionId}-cta-secondary`}
                  applyTopPadding
                  ariaLabel={ctaSecondary.ariaLabel}
                  linkUrl={ctaSecondary.linkUrl}
                  themeType={themeType}
                  variant='buttonLinkSecondary'
                  isInternalPdf={ctaSecondary.isInternalPdf && !ctaSecondary.isOutgoingLink}
                  isOutgoingLink={ctaSecondary.isOutgoingLink && !ctaSecondary.isInternalPdf}
                >
                  {ctaSecondary.buttonText}
                </LinkStyledButton>
              )}
            </>
          </ColStyled>
        </RowStyled>
      </ContainerStyled>
      {largeUp && (
        getBackgroundImageContainer
      )}
    </SectionStyled>
  );
};

HeroSectionComponent.propTypes = {
  /** required ariaLabel for background image */
  backgroundImageAriaLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** href or src of desktop jpg image to load */
  backgroundImageDesktop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** Webp version of backgroundImage desktop */
  backgroundImageDesktopWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** base64 encoded desktop 'fuzzy' image for initial display before full image renders */
  backgroundImageDesktopLowRes: PropTypes.any.isRequired,
  /** base64 encoded tablet 'fuzzy' image for initial display before full image renders */
  backgroundImageTabletLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** href or src of tablet jpg image to load */
  backgroundImageTablet: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Webp version of backgroundImage Tablet */
  backgroundImageTabletWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** base64 encoded mobile 'fuzzy' image for initial display before full image renders */
  backgroundImageMobileLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** href or src of mobile jpg image to load */
  backgroundImageMobile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** Webp version of backgroundImage Mobile */
  backgroundImageMobileWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** optional additional className */
  className: PropTypes.string,
  /** Props passed to primary CTA button - if object not provided, will not show button */
  ctaPrimary: PropTypes.shape({
    linkUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    ariaLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isInternalPdf: PropTypes.bool,
    isOutgoingLink: PropTypes.bool,
  }),
  /** Props passed to secondary CTA button - if object not provided, will now show button */
  ctaSecondary: PropTypes.shape({
    linkUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    ariaLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isInternalPdf: PropTypes.bool,
    isOutgoingLink: PropTypes.bool,
  }),
  /** Required Description Text */
  descriptionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** Required Heading Text (h2) */
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** Required Product Name (h1) */
  productName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** required section id */
  sectionId: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

HeroSectionComponent.defaultProps = { ctaSecondary: null };

export default HeroSectionComponent;
