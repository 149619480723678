// deprecated
import React from 'react';
import PropTypes from 'prop-types';
// components
import Link from '../../../components/Link';
import Typography from '../../../foundations/Typography';
import { Col, Row } from '../../../foundations/Grid';

import {
  ContentColStyled,
  DivButtonContainer,
  PhotoColStyled,
  PictureFRPhotoStyled,
  TypographyHeadingStyled,
} from './styles';

const DefaultCard = ({
  cta,
  desktopWebpSrc,
  disableLazyLoad,
  headingText,
  imageSrc,
  imageVariant,
  mobileImageSrc,
  mobileWebpSrc,
  subText,
  text,
  themeType,
}) => {
  const contents = <>
    <TypographyHeadingStyled
      component='h2'
      themeType={themeType}>
      {headingText}
    </TypographyHeadingStyled>
    {/* TODO: ensure defaultText can handle html being passed in or maybe a react component? */}
    <Typography themeType={themeType}>
      {text}
    </Typography>
    {subText && (
      <Typography
        weight={400}
        themeType={themeType}>{/* TODO: Typography no longer takes weight */}
        {subText}
      </Typography>
    )}
    {cta
      && (
        <DivButtonContainer>
          <Link
            id='advisor-card-connect-button'
            themeType={themeType}
            variant='buttonLinkPrimary'
            linkUrl={cta.linkUrl}
            {...cta.ariaLabel && { 'aria-label': cta.ariaLabel }}>
            {cta.linkText}
          </Link>
        </DivButtonContainer>
      )}
  </>;

  return imageSrc
    ? (
      <Row align='center'>
        <PhotoColStyled medium={4}>
          <PictureFRPhotoStyled
            src={imageSrc}
            mobileSrc={mobileImageSrc}
            mobileWebpSrc={mobileWebpSrc}
            desktopWebpSrc={desktopWebpSrc}
            altText={'Picture of a financial representative'}
            lazyLoad={!disableLazyLoad}
            width='200'
            height='100%'
            variant={imageVariant}
          />
        </PhotoColStyled>
        <ContentColStyled medium={8}>
          {contents}
        </ContentColStyled>
      </Row>
    )
    : (
      <Row styles={{ textAlign: 'center' }}>
        <Col
          small={10}
          medium={8}>
          {contents}
        </Col>
      </Row>
    );
};

DefaultCard.propTypes = {
  desktopWebpSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  imageOnTop: PropTypes.bool,
  imageSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  imageVariant: PropTypes.oneOf(['rectangular', 'round']),
  mobileImageSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  mobileWebpSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  cta: PropTypes.shape({
    linkText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    linkUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
  }),
  disableLazyLoad: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

DefaultCard.defaultProps = {
  desktopWebpSrc: '/assets/images/frs/leo-tucker-desktop-v7.webp',
  headingText: 'Take the next step.',
  imageSrc: '/assets/images/frs/leo-tucker-desktop-v7.jpg',
  mobileImageSrc: '/assets/images/frs/leo-tucker-mobile-v6.jpg',
  mobileWebpSrc: '/assets/images/frs/leo-tucker-mobile-v6.webp',
  text: 'Our advisors are here to give you more of the information you want, and the knowledge you never knew you needed. To get to your next goal, and the next.',
  disableLazyLoad: false,
};

export default DefaultCard;
