import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../../foundations/Grid';
import AssistiveText from '../../../../components/AssistiveText';
import UserLoginButton from '../UserLoginButton';
import Search from '../Search';

import {
  NavStyled,
  ColStyledNavContainer,
  UlStyled,
  SearchButtonStyled,
  LiStyled,
  AStyled,
  DivStyled,
  MagnifyingGlassStyled,
} from './styles';

const utilityNavId = 'nmx-utility-nav';

export const UtilityNavigationComponent = ({ config }) => (
  <div id={utilityNavId}>
    <Row align="right">
      <ColStyledNavContainer id="nav-utility-container">
        <NavStyled
          aria-label="Utility Navigation"
          id="nmx-nav-utility"
          itemScope
          itemType="https://schema.org/SiteNavigationElement">
          <AssistiveText component="h6">Utility Navigation</AssistiveText>
          <UlStyled
            role="menu"
            className="reduced">
            <LiStyled
              role="none"
              itemProp="name"
              id="nmx-nav-link-login-button-container">
              <DivStyled>
                <UserLoginButton
                  config={config}
                  parentId={`#${utilityNavId}`}/>
              </DivStyled>
            </LiStyled>
            <LiStyled
              role="none"
              itemProp="name"
              mobileButton={true}>
              <AStyled
                role="menuitem"
                itemProp="url"
                href="/claims/"
                id="nmx-nav-link-utility-claims">
                Claims
              </AStyled>
            </LiStyled>
            <LiStyled
              role="none"
              itemProp="name"
              mobileButton={true}>
              <AStyled
                isFAFA={true}
                role="menuitem"
                itemProp="url"
                href="/find-a-financial-advisor/"
                id="nmx-nav-link-utility-fafa">
                Find a Financial Advisor
              </AStyled>
            </LiStyled>
            <LiStyled
              role="none"
              itemProp="name"
              id="nmx-nav-link-search-container">
              <SearchButtonStyled
                role="menuitem"
                id="nmx-nav-link-utility-search">
                Search
                <MagnifyingGlassStyled />
              </SearchButtonStyled>
            </LiStyled>
          </UlStyled>
        </NavStyled>
      </ColStyledNavContainer>
    </Row>
    <Row>
      <Col>
        <Search
          id="nmx-search-console"
          config={config} />
      </Col>
    </Row>
  </div>
);

UtilityNavigationComponent.propTypes = {
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
    public: PropTypes.shape({
      googleAnalyticsTrackingId: PropTypes.string,
      googlePlacesApiKey: PropTypes.string,
    }).isRequired,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
      userLogoutUrls: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

UtilityNavigationComponent.defaultProps = {
  config: {
    googleAnalyticsTrackingId: '<%=googleAnalyticsTrackingId%>',
    googlePlacesApiKey: '<%=googlePlacesApiKey%>',
    loginPageUrl: '<%=loginPageUrl%>',
    loginPageUrlBase: '<%=loginPageUrlBase%>',
    templateBasePath: '<%=templateBasePath%>',
    userLogoutUrls: '<%=userLogoutUrls%>',
  },
};

export default UtilityNavigationComponent;
