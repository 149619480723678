import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Session from '@nmx/utils/dist/utilities/frontend/session';
import { withTheme } from 'styled-components';
// styles
import {
  ColStyled,
  ContainerStyledStickyBanner,
  DismissStyled,
  LightBulbIconStyled,
  LinkStyled,
  RowStyled,
  TypographyStyled,
} from './styles';

export const getDisplayConfiguration = (
  DISMISSED_SESSION_KEY,
  setBannerConfig,
  setRetrievedBannerConfig,
  setStickyBannerDismissed,
  staticConfig,
) => {
  if (staticConfig) {
    // storybook/static config
    setBannerConfig(staticConfig.stickyBanner);
    if (Session.get(DISMISSED_SESSION_KEY) !== undefined) {
      setStickyBannerDismissed(Session.get(DISMISSED_SESSION_KEY));
    }
    setRetrievedBannerConfig(true);
    return;
  }

  setRetrievedBannerConfig(true);
  if (Session.get(DISMISSED_SESSION_KEY) !== undefined) {
    setStickyBannerDismissed(Session.get(DISMISSED_SESSION_KEY));
  }
};

const defaultConfig = {
  data: {
    text: 'Get your free financial plan.',
    ctaLinkText: 'Connect with an advisor',
    ctaLinkUrl: '/find-a-financial-advisor/',
    ctaLinkAriaLabel: 'Find out more about receiving a free financial plan by connecting with an advisor',
  },
  isActive: true,
};

export const StickyBannerModule = ({
  'data-app-name': appName = 'default',
  'data-static-config': staticConfig,
  'data-stickyBanner-id': stickyBannerId = 'nmx-sticky-banner',
}) => {
  // external els
  const nmxInformationalBanner = document.getElementById('nmx-informational-banner');

  // constants
  const SLIDE_IN_TIMER_COUNTDOWN = 10; // slide-in timer countdown (seconds)
  const DISMISSED_SESSION_KEY = `${appName}-dismiss-sticky-banner`;

  const [slideInCountdown, setSlideInCountdown] = useState(SLIDE_IN_TIMER_COUNTDOWN); // timer
  const [retrievedBannerConfig, setRetrievedBannerConfig] = useState(false); // triggers render to show banner
  const [stickyBannerDismissed, setStickyBannerDismissed] = useState(false);
  const [bannerConfig, setBannerConfig] = useState(defaultConfig);

  // on load
  useEffect(() => {
    getDisplayConfiguration(
      DISMISSED_SESSION_KEY,
      setBannerConfig,
      setRetrievedBannerConfig,
      setStickyBannerDismissed,
      staticConfig,
    );
  }, []);

  // countdown timer hook
  useEffect(() => {
    // kill countdown timer when reaches 0
    if (!slideInCountdown) return;

    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setSlideInCountdown(slideInCountdown - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [slideInCountdown]);

  const dismissStickyBanner = () => {
    setStickyBannerDismissed(true);
    Session.set(DISMISSED_SESSION_KEY, true);
  };

  return (
    (retrievedBannerConfig && bannerConfig.isActive && !stickyBannerDismissed) && (
      <ContainerStyledStickyBanner
        id={stickyBannerId}
        className='nmx-sticky-banner'
        isVisible={slideInCountdown === 0}
        informationalBannerHeight={nmxInformationalBanner && nmxInformationalBanner.clientHeight}>
        <RowStyled>
          <ColStyled xsmall={2}>
            <LightBulbIconStyled />
          </ColStyled>
          <ColStyled
            xsmall={10}
            variant='col-right'>
            <TypographyStyled
              className='nmx-sticky-banner--text'
              themeType='darkTheme'
              weight={400}
              disableBottomPadding>
              {bannerConfig.data.text}&nbsp;
              <LinkStyled
                id='nmx-sticky-banner-link'
                className='nmx-link--stand-alone'
                variant='secondary'
                linkUrl={bannerConfig.data.ctaLinkUrl}
                {...bannerConfig.data.ctaLinkAriaLabel !== '' && { ariaLabel: bannerConfig.data.ctaLinkAriaLabel }}>
                {bannerConfig.data.ctaLinkText}
              </LinkStyled>
            </TypographyStyled>
          </ColStyled>
          <DismissStyled
            onClick={dismissStickyBanner}
            ariaLabel='Dismiss Sticky Banner'
          />
        </RowStyled>
      </ContainerStyledStickyBanner>
    )
  );
};

StickyBannerModule.propTypes = {
  'data-stickyBanner-id': PropTypes.string,
  'data-static-config': PropTypes.object,
  'data-app-name': PropTypes.string,
};

export default withTheme(StickyBannerModule);
