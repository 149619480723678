import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Button from '../Button';
import { getSectionBackgroundColor } from '../SectionContainer/styles';
import { getVerticalRhythmBottomPadding } from '../../foundations/Typography/styles';
import Typography from '../../foundations/Typography';

export const SpanStyledCollapsibleContainer = styled.span`
  ${({ theme, typographyProps }) => `
      position: relative;
      display: block;
      height: auto;
      padding-bottom: ${getVerticalRhythmBottomPadding(typographyProps.component, typographyProps.disableBottomPadding, theme.mediumUp(), typographyProps.nextElement, theme.smallUp(), typographyProps.variant)};
    `};
`;

export const TypographyStyled = styled(Typography)`
  ${({ clampedLines, isExpanded }) => css`
    position: relative;
    height: 100%;
    padding-bottom: 0; /** hard-code bottom-padding to override incoming typographyProps on Typography element: should always be set in order for #expand-collapse-typography-button to be properly aligned in container */
    display: ${isExpanded ? 'block' : '-webkit-box'};
    -webkit-line-clamp: ${clampedLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `};
`;

export const ButtonStyled = styled(Button)`
  ${({ backgroundVariant, branding, isExpanded, theme, themeType }) => {
    const thisBackgroundStyle = getSectionBackgroundColor(backgroundVariant, branding, theme, themeType);
    return css`
      min-height: inherit;
      font-size: inherit;
      line-height: inherit;
      font-style: inherit;
      letter-spacing: inherit;
      text-wrap: balance;
      ${!isExpanded
        && `
          position: absolute;
          right: 0;
          bottom: 0;
          border-radius: 0;
          background-color: ${thisBackgroundStyle};
          ::before {
            content:' ';
            position: absolute;
            display: block;
            top: 0;
            left: ${rem(-50)};
            width: ${rem(50)};
            height: 100%;
            background: linear-gradient(90deg, transparent, ${thisBackgroundStyle} 80%);
          }
        `}
      ${isExpanded
        && `
          display: inline-block;
          text-indent: ${rem(5)};
          float: right;
        `}
      && {
        &:hover {
          background-color: ${thisBackgroundStyle};
        }
      }
    `;
  }}
`;
