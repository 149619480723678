import SearchService from '@nmx/utils/dist/services/SearchService/Frontend';
import Session from '@nmx/utils/dist/utilities/frontend/session';

const getAgentIdFromTheBrowser = (agentNumberFromTealium, agentNumberFromLocalStorage, agentNumberFromSessionStorage, isTesting, mockAgentId) => {
  let res = '';
  if (isTesting) {
    res = mockAgentId;
  } else if (!!agentNumberFromTealium && agentNumberFromTealium !== 'null' && agentNumberFromTealium !== 'undefined') {
    res = agentNumberFromTealium;
  } else if (!!agentNumberFromLocalStorage && agentNumberFromLocalStorage !== 'null' && agentNumberFromLocalStorage !== 'undefined') {
    res = agentNumberFromLocalStorage;
  } else if (!!agentNumberFromSessionStorage && agentNumberFromSessionStorage !== 'null' && agentNumberFromSessionStorage !== 'undefined') {
    res = agentNumberFromSessionStorage;
  }
  return res;
};
// eslint-disable-next-line import/prefer-default-export
export const processAgentId = (config, agentObject, setIsEnsemble, setEnsembleData, setAgentData, isTesting, mockAgentId) => {
  // if agentObject is passed in from tealium, it takes precedence over agentNumber from the Session or local storage
  const agentNumberFromSessionStorage = Session.get('agentNumber');
  const agentNumberFromLocalStorage = localStorage.getItem('agentNumber');

  // trying to get agentId from tealium
  let tealiumData;
  let agentNumberFromTealium;

  try {
    tealiumData = JSON.parse(localStorage.getItem('teal_adbe_enrichment_data'));
    agentNumberFromTealium = tealiumData.properties[config.public.tealiumAgentIdProperty];
  } catch (err) {
    // do nothing
  }

  // set agentId from local or session storage
  let agentId = getAgentIdFromTheBrowser(agentNumberFromTealium, agentNumberFromLocalStorage, agentNumberFromSessionStorage, isTesting, mockAgentId);

  const removeTrailingSlash = (str) => str.replace(/\/+$/, '');

  if (agentId && agentId !== '') {
    // strip trailing slash if present
    agentId = removeTrailingSlash(agentId);
    // prepend leading zero if only 5 digits (if greater than 6, and is leading zero, truncate the first zero)
    agentId = agentId.toString().padStart(6, '0');

    while ((agentId.length > 6) && (agentId.charAt(0) === '0')) {
      agentId = agentId.substring(1);
    }
  }

  // check for query params and ensure agentObject is undefined
  if (agentId && agentId !== '' && !agentObject && !isTesting) {
    SearchService.getTeams({
      agentNumber: agentId,
      isEnsemble: true,
    }).then((rsp) => {
      setIsEnsemble(rsp.data.length === 1);
      setEnsembleData(rsp.data[0]);
    });

    SearchService.getFrs({ agentNumber: agentId }).then((rsp) => {
      // rsp.data will be an [] if no data is returned
      setAgentData(rsp.data[0]);
    });
  }
  return agentId;
};
