import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

// template components
import AssistiveText from '../../../components/AssistiveText';
import { Col, Row } from '../../../foundations/Grid';
import SectionContainer from '../../../components/SectionContainer';
import Link from '../../../components/Link';

// styles
import {
  LinkStyled,
  TypographyStyled,
} from './styles';

export const BrokerDealerDisclosureComponent = ({
  backgroundVariant,
  className,
  disableBottomPadding,
  moduleName,
  moduleVariation,
  sectionId,
  themeType,
}) => (
  <SectionContainer
    backgroundVariant={backgroundVariant}
    className={className}
    disableBottomPadding={disableBottomPadding}
    id={sectionId}
    moduleName={moduleName}
    moduleVariation={moduleVariation}
    themeType={themeType}>
    <AssistiveText component='h2'>Broker&#45;Dealer Disclosure</AssistiveText>
    <Row align='center'>
      <Col
        xsmall={12}
        medium={8}>
        <TypographyStyled
          variant='h3'
          themeType={themeType}>
          To learn more about Northwestern Mutual Investment Services, LLC and its financial professionals, visit <LinkStyled
            linkUrl='https://brokercheck.finra.org/'
            target='_blank'
            rel='nofollow noopener noreferrer'
            noWrap
            themeType={themeType}
            variant='primaryDeemphasize'>FINRA BrokerCheck&#174;</LinkStyled>.
        </TypographyStyled>
        <TypographyStyled
          disableBottomPadding
          themeType={themeType}>
          Visit our <Link
            linkUrl='/client-relationship-summary/'
            noWrap
            themeType={themeType}
            variant='primaryDeemphasize'>Client Relationship Summaries</Link> (Form CRS) for information about our firms that provide brokerage and advisory services.
        </TypographyStyled>
      </Col>
    </Row>
  </SectionContainer>
);

BrokerDealerDisclosureComponent.propTypes = {
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** Disable the default bottom padding for section */
  disableBottomPadding: PropTypes.bool,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
  /** optional moduleVariation used in Analytics */
  moduleVariation: PropTypes.string,
  /** required section id */
  sectionId: PropTypes.string.isRequired,
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

BrokerDealerDisclosureComponent.defaultProps = {
  backgroundVariant: 'lightA',
  disableBottomPadding: false,
  moduleName: 'broker-dealer-disclosure',
  moduleVariation: 'A',
  sectionId: 'broker-dealer-disclosure',
  themeType: 'lightTheme',
};

export default withTheme(BrokerDealerDisclosureComponent);
