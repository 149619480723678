import React from 'react';
import PropTypes from 'prop-types';

// components
import AssistiveText from '../../components/AssistiveText';
import { Row } from '../../foundations/Grid';
import SectionContainer from '../../components/SectionContainer';
import Slider from '../../components/Slider';
import Typography from '../../foundations/Typography';

// styles
import {
  BlockquoteStyled,
  ColStyledDisclosure,
  ColStyledTestimonialContainer,
  FigcaptionStyled,
  FigureStyled,
  IconStyledQuotation,
  TypographyStyledCitation,
  TypographyStyledDescription,
} from './styles';

export const getSectionHeading = (themeType, thisTestimonialConfig) => (thisTestimonialConfig.sectionHeadingDisplayed || thisTestimonialConfig.sectionHeadingDisplayed === null // allows for default display if sectionHeadingDisplayed not set to false
  ? <Typography
    align='center'
    component='h2'
    nextElement='button'
    themeType={themeType}
  >
    {thisTestimonialConfig.sectionHeading}
  </Typography>
  : <AssistiveText component='h2'>
    {thisTestimonialConfig.sectionHeading}
  </AssistiveText>
);

export const getFigcaptionSource = (thisTestimonialContent) => (thisTestimonialContent.figcaption
  ? <span>
    {thisTestimonialContent.hasLeadingDash
      && <>&#8212;</> // render leading em dash
    }
    <span itemProp='name'>{thisTestimonialContent.figcaption}</span>
  </span>
  : null);

export const getCitationContent = (thisTestimonialContent) => (thisTestimonialContent.figcaption
  && thisTestimonialContent.cite
  ? <>
    <span>, </span><cite>{thisTestimonialContent.cite}</cite>
  </>
  : null);

export const TestimonialComponent = ({
  backgroundVariant,
  className,
  disableSwiperScripts,
  moduleName,
  moduleVariation,
  sectionId,
  testimonialConfig,
  themeType,
}) => {
  let thisTestimonialConfig;
  if (testimonialConfig.swiperCarousel) {
    thisTestimonialConfig = testimonialConfig.swiperCarousel; // deprecate unnecessary swiperCarousel data object layer, remove once all consuming repos have updated.
  } else {
    thisTestimonialConfig = testimonialConfig;
  }

  const getTestimonial = (thisTestimonialSliderContentItem) => {
    const thisTestimonialContent = thisTestimonialSliderContentItem.data;
    return {
      content: <FigureStyled
        itemScope
        itemType='https://schema.org/Quotation'
        style={{ textAlign: thisTestimonialContent.centered ? 'center' : 'left' }}
      >
        <BlockquoteStyled>
          <TypographyStyledDescription
            disableBottomPadding
            {...thisTestimonialContent.textProps}
            themeType={themeType}
            variant='h2'
          >
            {thisTestimonialContent.text}
          </TypographyStyledDescription>
        </BlockquoteStyled>
        {(thisTestimonialContent.figcaption || thisTestimonialContent.cite) && (
          <FigcaptionStyled figcaptionCentered={thisTestimonialContent.figcaptionCentered}>
            <TypographyStyledCitation
              component='cite'
              disableBottomPadding
              itemProp='creator'
              itemScope
              itemType={thisTestimonialContent.figcaptionCreatorType === 'organization' ? 'https://schema.org/Organization' : 'https://schema.org/Person'} // only 2 creator types allowed for Quotation
              themeType={themeType}
            >
              {getFigcaptionSource(thisTestimonialContent)}
              {getCitationContent(thisTestimonialContent)}
            </TypographyStyledCitation>
          </FigcaptionStyled>
        )}
      </FigureStyled>,
    };
  };

  const buildSliderConfig = (thisTestimonialSliderConfig) => {
    const thisDynamicSliderConfig = {
      autoplay: thisTestimonialSliderConfig.autoplay,
      autoplayDelay: thisTestimonialSliderConfig.autoplayDelay,
    };
    return thisDynamicSliderConfig;
  };

  const buildSliderContent = (thisTestimonialSliderContent) => {
    const thisDynamicSliderContent = [];
    thisTestimonialSliderContent.map((thisTestimonialSliderContentItem) => (
      thisDynamicSliderContent.push(getTestimonial(thisTestimonialSliderContentItem))
    ));
    return thisDynamicSliderContent;
  };

  return (
    <SectionContainer
      backgroundVariant={backgroundVariant}
      className={className}
      id={sectionId}
      moduleName={moduleName}
      moduleVariation={moduleVariation}
      themeType={themeType}
    >
      <Row>
        <ColStyledTestimonialContainer>
          {getSectionHeading(themeType, thisTestimonialConfig)}
          {thisTestimonialConfig.hasQuotationIcon && (
            <IconStyledQuotation />
          )}
          <Slider
            disableSwiperScripts={disableSwiperScripts}
            sliderConfig={buildSliderConfig(thisTestimonialConfig)}
            sliderContent={buildSliderContent(thisTestimonialConfig.slides)}
            themeType={themeType}
          />
        </ColStyledTestimonialContainer>
      </Row>
      <Row align='center'>
        <ColStyledDisclosure
          align='center'
          medium={8}
        >
          {thisTestimonialConfig.disclosure && (
            <Typography
              component='small'
              themeType={themeType}
            >
              {thisTestimonialConfig.disclosure}
            </Typography>
          )}
        </ColStyledDisclosure>
      </Row>
    </SectionContainer>
  );
};

TestimonialComponent.propTypes = {
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** optional boolean to disable loading of swiper scripts */
  disableSwiperScripts: PropTypes.bool,
  /** optional additional className */
  className: PropTypes.string,
  /** optional module name */
  moduleName: PropTypes.string,
  /** optional module variation */
  moduleVariation: PropTypes.string,
  /** required section id */
  sectionId: PropTypes.string.isRequired,
  /** testimonial configuration object  */
  testimonialConfig: PropTypes.shape({
    /** base object level prop, displays disclosure text under Testimonial content */
    disclosure: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    /** base object level prop, displays quotation illustrative icon over Testimonial content */
    hasQuotationIcon: PropTypes.bool,
    /** required base object level prop, displays section heading over Testimonial content */
    sectionHeading: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    /** Allows the sectionHeading to be hidden from presentation */
    sectionHeadingDisplayed: PropTypes.bool,
    /** required base object level prop, contains array of slide content data */
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        /** required slides object that contains slide content */
        data: PropTypes.shape({
          /** centers Testimonial text */
          centered: PropTypes.bool,
          /** optional citation prop, typically set to a date (year) for this Testimonial application */
          cite: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
          ]),
          /** optional figcaption prop, typically set to the testimonial author for this Testimonial application */
          figcaption: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
          ]),
          /** centers figcaption if set to true */
          figcaptionCentered: PropTypes.bool,
          /** used in conjunction with supplied figcaption prop, used in schema metadata, used for SEO */
          figcaptionCreatorType: PropTypes.oneOf(['', 'organization', 'person']),
          /** optional hasLeadingDash prop adds an em dash before figcaption */
          hasLeadingDash: PropTypes.bool,
          /** required prop that supplies main Testimonial text content */
          text: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
          ]).isRequired,
          /** optional textProps prop that allows developer to add additional attributes and styles to main Testimonial text content */
          textProps: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
          ]),
        }).isRequired,
      }),
    ).isRequired,
    /** legacy data object middle layer */
    swiperCarousel: PropTypes.object, // deprecated, remove in 4.x.x
  }).isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

TestimonialComponent.defaultProps = {
  backgroundVariant: 'lightA',
  /** optional boolean to disable loading of swiper scripts */
  disableSwiperScripts: false,
  moduleName: 'informational-module',
  moduleVariation: 'A',
  themeType: 'lightTheme',
};

export default TestimonialComponent;
