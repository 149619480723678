import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, events } from '@gelato/analytics-datalayer';
import FrontendHttpService from '@nmx/utils/dist/services/FrontendHttpService';
import { newRelicJSError } from '@nmx/utils/dist/utilities/frontend/Analytics/new_relic_helper';
// template components
import { Col, Row } from '../../foundations/Grid';
import Loading from '../../components/Loading';
import Typography from '../../foundations/Typography';
import WidowBlock from '../../components/WidowBlock';
// Inputs
import EmailInput from './inputs/EmailInput';
import FirstNameInput from './inputs/FirstNameInput';
import LastNameInput from './inputs/LastNameInput';
// image imports
import LowerLeftDotPattern from './icons/LowerLeftCornerDotPattern';
import UpperRightDotPattern from './icons/UpperRightCornerDotPattern';
// styles
import {
  ButtonStyled,
  ColContainerStyled,
  ColInputContainerStyled,
  DivFormContainerStyled,
  DivLeftPatternContainerStyled,
  DivLoadingContainerStyled,
  DivRightPatternContainerStyled,
  FieldsetStyled,
  FormStyled,
  HandDrawnArrowStyled,
  HandDrawnCheckmarkStyled,
  LegendStyled,
  RowConfirmationStyled,
  SectionContainerStyled,
  SpanStyledIconContainer,
  TypographyHeaderStyled,
  TypographyStyled,
} from './styles';
// utilities
import {
  buildPreleadPayload,
  validateFirstName,
  validateLastName,
  validateEmail,
} from './utilities/utilities';
// adobe variables
const {
  FORM_COMPLETE_FAILURE,
  FORM_COMPLETE,
  FORM_INTERACT,
  FORM_LOAD,
} = CONSTANTS.NAME_PUSH_EVENTS;
const { SUSPECT_EMAIL_CAPTURE_FORM_PREFIX } = CONSTANTS.NAME_LEAD_FORM_EXPERIENCES;
const { EMAIL_CAPTURE } = CONSTANTS.NAME_LEAD_FORM_SUBMIT_TYPE;

const preLeadHttpService = new FrontendHttpService({
  baseURL: '<%=preLeadApiBaseUrl%>',
  fullResponse: true,
});

export const SuspectEmailCaptureFormComponent = (props) => {
  const {
    backgroundVariant,
    className,
    config,
    customSuccessMessage,
    formLocation,
    headingText,
    journey,
    onFormEngaged,
    position,
    sectionId,
    submitButtonAriaLabel,
    submitButtonText,
    themeType,
  } = props;

  const thisPosition = `${position} ${sectionId}`; // position value for analytics so they can distinguish which prelead form was interacted with or submitted

  const PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM_WITH_JOURNEY = SUSPECT_EMAIL_CAPTURE_FORM_PREFIX + journey;

  // React state Hooks ////////////////////////////////
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  // Error messages are set by this component's native utilities.js validators.
  const [errorMessages, setErrorMessages] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [failedPreviousAttempt, setFailedPreviousAttempt] = useState(false);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowingThankYou, setIsShowingThankYou] = useState(false);
  const [isShowingErrorScreen, setIsShowingErrorScreen] = useState(false);

  // EVENT HANDLERS ///////////////////////////////////////
  const handleChange = (e) => {
    // update values for user inputs
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    // Inform parent component of engagement with form
    onFormEngaged();
  };

  const handleInputClick = () => {
    onFormEngaged();
  };

  // Fires on input blur and autofills
  const analyticsPush = (e) => {
    const { value, name } = e.target;
    if (window && typeof window !== 'undefined') {
      events.leadForms.interact({
        experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM_WITH_JOURNEY,
        actionType: name,
        actionValues: value,
        eventName: FORM_INTERACT,
        position: thisPosition,
      });
    }
  };

  const validateForm = () => {
    const firstNameResponse = validateFirstName(values.firstName);
    const lastNameResponse = validateLastName(values.lastName);
    const emailResponse = validateEmail(values.email);

    setFormHasErrors(
      firstNameResponse[0]
      || lastNameResponse[0]
      || emailResponse[0],
    );

    setErrors({
      firstName: firstNameResponse[0],
      lastName: lastNameResponse[0],
      email: emailResponse[0],
    });

    // If there are errors, set the appropriate error message returned from the validators.
    setErrorMessages({
      firstName: firstNameResponse[1],
      lastName: lastNameResponse[1],
      email: emailResponse[1],
    });
  };

  // begin the submission process
  const handleReCaptchaCallback = (recaptchaReponseToken) => {
    let payload = null;

    payload = buildPreleadPayload(
      values,
      recaptchaReponseToken,
      journey,
    );

    payload.is_recaptcha_v3 = true;

    // ░██████╗██╗░░░██╗██████╗░███╗░░░███╗██╗████████╗ // ███████╗░█████╗░██████╗░███╗░░░███╗
    // ██╔════╝██║░░░██║██╔══██╗████╗░████║██║╚══██╔══╝ // ██╔════╝██╔══██╗██╔══██╗████╗░████║
    // ╚█████╗░██║░░░██║██████╦╝██╔████╔██║██║░░░██║░░░ // █████╗░░██║░░██║██████╔╝██╔████╔██║
    // ░╚═══██╗██║░░░██║██╔══██╗██║╚██╔╝██║██║░░░██║░░░ // ██╔══╝░░██║░░██║██╔══██╗██║╚██╔╝██║
    // ██████╔╝╚██████╔╝██████╦╝██║░╚═╝░██║██║░░░██║░░░ // ██║░░░░░╚█████╔╝██║░░██║██║░╚═╝░██║
    // ╚═════╝░░╚═════╝░╚═════╝░╚═╝░░░░░╚═╝╚═╝░░░╚═╝░░░ // ╚═╝░░░░░░╚════╝░╚═╝░░╚═╝╚═╝░░░░░╚═╝

    try {
      preLeadHttpService.post('/prelead', payload)
        .then(() => {
          // Success!
          setIsShowingThankYou(true);
          setIsSubmitting(false);
          if (window && typeof window !== 'undefined') {
            events.leadForms.complete({
              experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM_WITH_JOURNEY,
              submissionType: EMAIL_CAPTURE,
              eventName: FORM_COMPLETE,
              submissionData: payload,
              position: thisPosition,
            });
          }
        })
        .catch((error) => {
          setIsShowingErrorScreen(true);
          setIsSubmitting(false);
          console.error(error);
          newRelicJSError(`Error submitting prelead on ${formLocation}.`, error);
        });
    } catch (error) {
      setIsShowingErrorScreen(true);
      setIsSubmitting(false);
      console.error(error);
      newRelicJSError(`Error submitting prelead on ${formLocation}.`, error);
      events.leadForms.completeFailure({
        experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM_WITH_JOURNEY,
        errorMsg: `Unable to submit prelead email capture form with error ${error}`,
        eventName: FORM_COMPLETE_FAILURE,
      });
    }
  };

  // call this function when we've validated the form and are ready to submit
  const executeRecaptchaAndSubmit = () => {
    if (window.grecaptcha) {
      try {
        // recaptchaV3 method for executing
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(config.public.recaptchaV3, { action: 'submit' }).then((token) => {
            document
              .querySelectorAll('.recaptchaResponse')
              // eslint-disable-next-line no-param-reassign
              .forEach((elem) => { elem.value = token; });
            handleReCaptchaCallback(token);
          });
        });
      } catch (error) {
        // if recaptcha execute fails
        setIsShowingErrorScreen(true);
        setIsSubmitting(false);
        console.error(error);
        newRelicJSError(
          `Error executing recaptcha at form submit at path: ${window.location.pathname}.`,
          error,
        );
      }
    }
  };

  // call this function when we've validated the form and there's an error
  const resetRecaptcha = () => {
    if (window.grecaptcha) {
      try {
        // recaptchaV3 method for executing
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(config.public.recaptchaV3, { action: 'reset' })
            .then((token) => {
              document
                .querySelectorAll('.recaptchaResponse')
                // eslint-disable-next-line no-param-reassign
                .forEach((elem) => { elem.value = token; });
            });
        });
      } catch (error) {
        // if recaptcha execute fails, show error
        setIsShowingErrorScreen(true);
        setIsSubmitting(false);
        console.error(error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // final check for errors
    validateForm();
    setIsSubmitting(true);
  };

  // useEffect HOOKS ////////////////////////////////////
  useEffect(() => {
    events.leadForms.load({
      experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM_WITH_JOURNEY,
      eventName: FORM_LOAD,
    });
  }, []);

  // final check to make sure the form is error free and the user is submitting
  useEffect(() => {
    if (isSubmitting) {
      if (!formHasErrors) {
        executeRecaptchaAndSubmit();
      } else {
        setIsSubmitting(false);
        resetRecaptcha();
      }
    }
  }, [isSubmitting]);

  useEffect(() => {
    setFailedPreviousAttempt(formHasErrors);
  }, [formHasErrors]);

  useEffect(() => {
    // validate form in real-time if the user has had a failed attempt that gave user input errors.
    // A valid form will reactivate the submit button.
    if (failedPreviousAttempt) {
      validateForm();
    }
  }, [values]);

  return (
    <SectionContainerStyled
      className={className}
      id={sectionId}
      backgroundVariant={backgroundVariant}
      themeType={themeType}
      moduleName='suspect-email-capture-form-module'
      moduleVariation='A'>
      {/* HIDES DOT PATTERN FOR DARK THEME */}
      {(backgroundVariant !== 'darkDefault') && (
        <>
          <DivLeftPatternContainerStyled className='left-pattern-container'>
            <LowerLeftDotPattern />
          </DivLeftPatternContainerStyled>
          <DivRightPatternContainerStyled className='right-pattern-container'>
            <UpperRightDotPattern />
          </DivRightPatternContainerStyled>
        </>
      )}
      {/* SETS THE WIDTH OF THE WHITE BOX CONTAINER */}
      <Row align='center'>
        <Col
          medium={11}
          large={10}
          xxlarge={8}
          nested>
          {/* WHITE BOX */}
          <DivFormContainerStyled
            id={`${sectionId}-container`}
            themeType={themeType}>
            <Row align='center'>
              {/* Full width for mobile when darkTheme */}
              <ColContainerStyled
                xsmall={themeType === 'darkTheme' ? 12 : 11}
                medium={11}>
                {/* SETS THE WIDTH OF THE FORM INPUTS AND CONTENT */}
                <Row align='center'>
                  {(!isShowingThankYou && !isShowingErrorScreen && !isSubmitting) && (
                    <Col
                      xsmall={10}
                      xlarge={12}
                      align='center'
                      nested>
                      <TypographyHeaderStyled
                        id='suspect-email-capture-form-header'
                        component='h2'
                        variant='h4'
                        themeType={themeType}>
                        {headingText}
                        <SpanStyledIconContainer>
                          <HandDrawnArrowStyled />
                        </SpanStyledIconContainer>
                      </TypographyHeaderStyled>
                    </Col>
                  )}
                  {/* FORM CONTAINER */}
                  <Col>
                    {(!isShowingThankYou && !isShowingErrorScreen && !isSubmitting) && (
                      <FormStyled
                        id={`${sectionId}-form`}
                        onSubmit={handleSubmit}>
                        <FieldsetStyled>
                          <LegendStyled>
                            Enter your contact information to receive weekly emails about reaching your goals.
                          </LegendStyled>
                          <Row align='center'>
                            {/* FIRST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                            <ColInputContainerStyled
                              id={`${sectionId}-first-name-input-container`}
                              small={6}
                              large={3}>
                              <FirstNameInput
                                value={values.firstName}
                                onBlur={analyticsPush}
                                onChange={handleChange}
                                onClick={handleInputClick}
                                hasError={errors.firstName}
                                helperText={errorMessages.firstName}
                                themeType={themeType}
                              />
                            </ColInputContainerStyled>
                            {/* LAST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                            <ColInputContainerStyled
                              id={`${sectionId}-last-name-input-container`}
                              small={6}
                              large={3}>
                              <LastNameInput
                                value={values.lastName}
                                onBlur={analyticsPush}
                                onChange={handleChange}
                                onClick={handleInputClick}
                                hasError={errors.lastName}
                                helperText={errorMessages.lastName}
                                themeType={themeType}
                              />
                            </ColInputContainerStyled>
                            {/* EMAIL INPUT //////////////////////////////////////////////////////////////////// */}
                            <ColInputContainerStyled
                              id={`${sectionId}-email-input-container`}
                              medium={8}
                              large={6}
                              xlarge={4}>
                              <EmailInput
                                value={values.email}
                                onBlur={analyticsPush}
                                onChange={handleChange}
                                onClick={handleInputClick}
                                hasError={errors.email}
                                helperText={errorMessages.email}
                                themeType={themeType}
                              />
                            </ColInputContainerStyled>
                            <ColInputContainerStyled
                              id={`${sectionId}-submit-button-container`}
                              medium={4}
                              xlarge={2}
                              disableBottomPadding>
                              <ButtonStyled
                                id='suspect-email-capture-form-submit-button'
                                type='submit'
                                disabled={formHasErrors}
                                formvariant='primary'
                                ariaLabel={submitButtonAriaLabel}
                                themeType={themeType}>
                                {submitButtonText || 'Sign me up'}
                              </ButtonStyled>
                            </ColInputContainerStyled>
                          </Row>
                        </FieldsetStyled>
                      </FormStyled>
                    )}

                    {isSubmitting && (
                      <RowConfirmationStyled align='center'>
                        <Col
                          align='center'
                          nested>
                          <DivLoadingContainerStyled>
                            <Loading/>
                          </DivLoadingContainerStyled>
                        </Col>
                      </RowConfirmationStyled>
                    )}

                    {isShowingThankYou && (
                      <RowConfirmationStyled
                        id={`${sectionId}-confirmation-row`}
                        align='center'>
                        <Col
                          align='center'
                          nested>
                          <Typography
                            component='h2'
                            variant='h4'
                            themeType={themeType}>
                            Got it&#33; Thanks, <span id='prelead-suspect-email-capture-form-confirmation-first-name'>{values.firstName}</span>.
                            <SpanStyledIconContainer>
                              <HandDrawnCheckmarkStyled />
                            </SpanStyledIconContainer>
                          </Typography>
                          <TypographyStyled
                            themeType={themeType}
                            disableBottomPadding>
                            {customSuccessMessage}
                          </TypographyStyled>
                        </Col>
                      </RowConfirmationStyled>
                    )}

                    {isShowingErrorScreen && (
                      <RowConfirmationStyled
                        id={`${sectionId}-error-row`}
                        align='center'>
                        <Col
                          align='center'
                          nested>
                          <Typography
                            variant='h2'
                            themeType={themeType}>
                            Oops&#33; Something went wrong.
                          </Typography>
                          <Typography
                            variant='h2'
                            themeType={themeType}
                            disableBottomPadding>
                            Please try again later.
                          </Typography>
                        </Col>
                      </RowConfirmationStyled>
                    )}
                  </Col>
                </Row>
              </ColContainerStyled>
            </Row>
          </DivFormContainerStyled>
        </Col>
      </Row>
      <input
        type='hidden'
        name='recaptcha_response'
        className='recaptchaResponse' />
    </SectionContainerStyled>
  );
};

SuspectEmailCaptureFormComponent.propTypes = {
  /**  backgroundVariant determines the look and feel of the Section */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** optional additional className */
  className: PropTypes.string,
  /** optional custom success message */
  customSuccessMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** currently used for form recaptcha options */
  config: PropTypes.shape({ public: PropTypes.shape({ recaptchaInvisible: PropTypes.string }).isRequired }),
  /** required string used for analytics */
  formLocation: PropTypes.string.isRequired,
  /** string or object fragment for the form heading text */
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** required for prelead journey payload */
  journey: PropTypes.string.isRequired,
  /** This callback is used to tell parent component that the form has first been engaged with */
  onFormEngaged: PropTypes.func,
  /** optional custom position which gets passed to adobe analytics */
  position: PropTypes.string,
  /** Id for the section */
  sectionId: PropTypes.string.isRequired,
  /** optional string or object fragment for the submit button text aria-label */
  submitButtonAriaLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** optional string or object fragment for the submit button text */
  submitButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
};

SuspectEmailCaptureFormComponent.defaultProps = {
  backgroundVariant: 'lightA',
  // Necessary to allow legacy apps to capture the configs with string replace
  config: { public: { recaptchaInvisible: '<%=recaptchaInvisible%>' } },
  customSuccessMessage: <>We&#39;re excited to share our Life &#38; Money newsletter with you. The next edition will arrive shortly.</>,
  headingText: <>Get our monthly Life &#38; Money newsletter filled with financial tips, tools, <WidowBlock>and more.</WidowBlock></>,
  onFormEngaged: () => {},
  position: 'static',
  submitButtonAriaLabel: 'Submit form to sign up for weekly emails.',
  themeType: 'lightTheme',
};

export default SuspectEmailCaptureFormComponent;
