import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
// template components
import { Col } from '../../foundations/Grid';
import Box from '../../components/Box';
import Link from '../../components/Link';
import Typography from '../../foundations/Typography';
// icon imports
import caratRight from '../../foundations/Icon/icons/utility/CaratRight';
// styles
import {
  ArrowRightIconStyled,
  ColStyledLinkContainer,
  DivStyledProductCard,
  LinkStyledCondensed,
  RowStyledHeading,
  RowStyledProductCard,
  Styles,
  TypographyStyledCardText,
  TypographyStyledHeading,
  WidowBlockStyled,
} from './styles';

// If the card is condensed, the button overlay will kick in to handle link functionality,
// and the button props will be applied to the CTA. (buttonId and buttonUrl)

const getIcon = (icon, styles) => {
  if (typeof icon === 'object') {
    return icon;
  }
  const IconToRender = icon;
  return <IconToRender style={styles.IconCardMainStyled} />;
};

export const ProductCardComponent = ({
  ariaLabel,
  backgroundVariant,
  branding,
  cardFootnote,
  cardHeading,
  cardId,
  cardText,
  className,
  condensed,
  config,
  contentBlock,
  fixHeight,
  icon,
  linkId,
  linkText,
  linkUrl,
  themeType,
  withHeadingAccent,
}) => {
  const styles = Styles();
  let cardHeadingAsString;
  let cardHeadingWithoutLastTwoWords;
  let cardHeadingLastTwoWords;
  // if the cardHeading being passed in is a fragment/object
  if (cardHeading) {
    if (typeof cardHeading === 'object') {
    // stringify the cardHeading, since it may be coming in as an object/fragment
      cardHeadingAsString = cardHeading.props.children.toString();
    } else {
    // if the cardHeading props is already a string, we will just use that.
      cardHeadingAsString = cardHeading;
    }
    // slice and dice the cardHeading string for proper injection into the condensed variant. This ultimately prevents the arrow adornment from wrapping on its own line.
    cardHeadingWithoutLastTwoWords = cardHeadingAsString.split(' ').slice(0, -2).join(' ');
    cardHeadingLastTwoWords = cardHeadingAsString.split(' ').splice(-2).join(' ');
  }

  const ProductCardWrapper = ({ children }) => (condensed
    ? (
      <LinkStyledCondensed
        linkUrl={linkUrl}
        id={linkId}
        variant='primaryDeemphasize'>
        {children}
      </LinkStyledCondensed>
    )
    : children
  );

  return (
    <ProductCardWrapper>
      <DivStyledProductCard
        backgroundVariant={backgroundVariant}
        branding={branding}
        className={className}
        id={cardId}
        condensed={condensed}
        fixHeight={fixHeight}
        themeType={themeType}>
        <RowStyledProductCard>
          <Col>
            <RowStyledHeading>
              {cardHeading && (
                <TypographyStyledHeading
                  component='h3'
                  variant='h4'
                  condensed={condensed}
                  withHeadingAccent ={withHeadingAccent}
                  themeType={themeType}>
                  {/* If not condensed, just render the cardHeading string, otherwise, use the split out version so we can properly append the arrow icon and prevent the icon wrapping as a widow */}
                  {!condensed ? (
                    cardHeading
                  ) : (
                    <>
                      {cardHeadingWithoutLastTwoWords} <WidowBlockStyled>{cardHeadingLastTwoWords} <ArrowRightIconStyled className='icon-product-callout-arrow' /></WidowBlockStyled>
                    </>
                  )}
                </TypographyStyledHeading>
              )}
              {icon && !condensed && (
                getIcon(icon, styles)
              )}
            </RowStyledHeading>

            {/* Optional cardText props for rendering simple para text */}
            {(cardText && !condensed)
            && (
              <TypographyStyledCardText themeType={themeType}>
                {cardText}
              </TypographyStyledCardText>
            )}
            {/* Optional content block props for rendering more complex values; e.g. an unordered list */}
            {(contentBlock && !condensed)
            && (
              <>
                {contentBlock}
              </>
            )
            }
          </Col>
        </RowStyledProductCard>
        {linkText && (
          <RowStyledProductCard
            condensed={condensed}
            className='nmx-no-print'>
            <ColStyledLinkContainer>
              <Link
                id={linkId} // TODO: confirm card link size with Creative
                variant='buttonLinkTertiary'
                linkUrl={linkUrl}
                ariaLabel={ariaLabel}
                config={config}
                endAdornment={caratRight}
                endAdornmentStylesReset={true}
                themeType={themeType}>
                {linkText}
              </Link>
            </ColStyledLinkContainer>
          </RowStyledProductCard>
        )}
        {/* TODO: cardFootnote is used by FWM directly in product callout. Swap for backend service that can be rendered at bottom of page similar to NMCOM & Gelato. */}
        {cardFootnote && !condensed && (
          <Box sx={{ mt: 1 }}> {/* not digging this Box thing */}
            <RowStyledProductCard>
              <Col>
                <Typography
                  component='small'
                  disableBottomPadding
                  themeType={themeType}>
                  {cardFootnote}
                </Typography>
              </Col>
            </RowStyledProductCard>
          </Box>
        )}
      </DivStyledProductCard>
    </ProductCardWrapper>
  );
};

ProductCardComponent.propTypes = {
  /** backgroundVariant allows section cards to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf([
    'darkDefault',
    'lightA',
    'lightB',
    'lightC']),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** required id placed on each product callout card */
  cardId: PropTypes.string.isRequired,
  /** optional footnote string */
  cardFootnote: PropTypes.string,
  /** card Heading */
  cardHeading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** optional para card text */
  cardText: PropTypes.any,
  /** optional additional className */
  className: PropTypes.string,
  /** Triggers condensed product callout version */
  condensed: PropTypes.bool, // TODO: add as variant instead of bool? or rename to isCondensed?
  /** module config */
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  /** Optional content block props for rendering more complex values; e.g. an unordered list */
  contentBlock: PropTypes.any, // TODO: this can be consolidated, no need for all of these options, just use a variant prop to depict which style to render
  /** icon Component for this card */
  icon: PropTypes.any,
  /** optional link id */
  linkId: PropTypes.string,
  /** optional link text */
  linkText: PropTypes.string,
  /** optional link url */
  linkUrl: PropTypes.string,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']), // TODO: add in 'nmx-pcg' when ready
  /** optional prop to trigger heading accent */
  withHeadingAccent: PropTypes.bool,
};

ProductCardComponent.defaultProps = {
  backgroundVariant: 'lightB',
  branding: 'nm',
  condensed: false,
  config: { // TODO: this is good and could be used in other places like the Copyright Footer Section
    basePath: '<%=basePath%>',
    nmxTemplateVersion: '<%=nmxTemplateVersion%>',
  },
  themeType: 'lightTheme',
  withHeadingAccent: false,
};

export default withTheme(ProductCardComponent);
