import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { BaseFont } from './styles';

export const TypographyComponent = React.forwardRef(
  (
    {
      accent,
      align,
      ariaLabel,
      block,
      branding,
      children,
      className,
      color,
      component,
      italic,
      nextElement,
      styles,
      themeType,
      uppercase,
      variant,
      weight,
      ...rest
    },
    ref,
  ) => {
    const thisTypographyClassNames = {};
    let adjustedStyles = styles;

    thisTypographyClassNames[`nmx-${variant}`] = variant;

    if (color) {
      adjustedStyles = { ...adjustedStyles };
    }

    return (
      <BaseFont
        accent={accent}
        align={align}
        aria-label={ariaLabel}
        as={component || 'p'}
        block={block}
        branding={branding}
        className={classnames(thisTypographyClassNames, className)}
        component={component}
        italic={italic}
        nextElement={nextElement}
        ref={ref}
        style={adjustedStyles || {}}
        themeType={themeType}
        uppercase={uppercase}
        variant={variant}
        weight={weight}
        {...rest}>
        {children}
      </BaseFont>
    );
  },
);

TypographyComponent.propTypes = {
  /** optional accent */
  accent: PropTypes.oneOf(['pcgHeading', 'none']),
  /** Alignment for text component */
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right']), // TODO: how destructive would it be to set default='left?
  /** Optional ariaLabel, set to any to accommodate React.Fragment object, but should render as a string */
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** style typography innerText as block */
  block: PropTypes.bool,
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** The inner content to be rendered */
  children: PropTypes.node.isRequired,
  /** Custom class name to add to Type. */
  className: PropTypes.string,
  /** what DOM element the rendered Component should be */
  component: PropTypes.oneOf([
    'address',
    'cite',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'label',
    'p',
    'small',
    'span',
    'time',
  ]),
  /** Disable the default bottom margin for variant */
  disableBottomPadding: PropTypes.bool,
  /** What styles should be applied to the tag */
  styles: PropTypes.object,
  /** convert typography innerText to italic */
  italic: PropTypes.bool,
  /** style typography innerText as upperCase */
  uppercase: PropTypes.bool,
  /** optional props to pass in the next element to set spacing for the typography */
  nextElement: PropTypes.oneOf(['button', 'graphic', 'link', 'typography']),
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** What typography element should this text LOOK like */
  variant: PropTypes.oneOf([
    'eyebrow',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'jumbo-type', // deprecated // I don't think this is ever used - might be originally used for the old accolades
    'meta',
    'p',
    'p2',
    'small',
  ]),
  /** allows overriding of font-weight */
  weight: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700, 800, 900]), // TODO: name and export in a theme
  /** allows developer to boost specificity of weight styles, default set to specificity level 1 */
  // weightSpecifity: PropTypes.oneOf([1, 2, 3, 4]), // TODO: create a JS-in-Styled-Components function for this, default 1
};

TypographyComponent.defaultProps = {
  accent: null,
  branding: 'nm',
  className: null,
  component: 'p',
  disableBottomPadding: false,
  styles: null,
  themeType: 'lightTheme',
  uppercase: false,
};

export default TypographyComponent;
