import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  BaseButton,
  StartAdornmentContainerStyled,
  EndAdornmentContainerStyled,
} from './styles';

export const ButtonComponent = React.forwardRef(
  (
    {
      ariaLabel,
      branding,
      className,
      children,
      disabled,
      endAdornment: RenderEndAdornment,
      endAdornmentStylesReset,
      id,
      onClick,
      styles,
      startAdornment: RenderStartAdornment,
      startAdornmentStylesReset,
      themeType,
      type,
      variant,
      ...rest
    },
    ref,
  ) => (
    <BaseButton
      {...(ariaLabel && { 'aria-label': ariaLabel })} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
      branding={branding}
      className={classnames('nmx-button', className)}
      disabled={disabled}
      id={id}
      onClick={onClick}
      ref={ref}
      style={styles || {}}
      themeType={themeType}
      type={type}
      variant={variant}
      {...rest}>
      {RenderStartAdornment && (
        <StartAdornmentContainerStyled
          startAdornmentStylesReset={startAdornmentStylesReset}>
          <RenderStartAdornment />
        </StartAdornmentContainerStyled>
      )}
      {children}
      {RenderEndAdornment && (
        <EndAdornmentContainerStyled
          endAdornmentStylesReset={endAdornmentStylesReset}>
          <RenderEndAdornment />
        </EndAdornmentContainerStyled>
      )}
    </BaseButton>
  ),
);

ButtonComponent.propTypes = {
  /** Optional ariaLabel attribute, used for ADA to add more description to the Button (what will happen when clicked) */
  ariaLabel: PropTypes.string,
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** Optional custom class name */
  className: PropTypes.string,
  /** Inner Text of button */
  children: PropTypes.node.isRequired,
  /** optional disabled prop */
  disabled: PropTypes.bool,
  /** optional icon end adornment displayed after button text - PASS IN SPECIFIC ICON COMPONENT */
  // TODO: lazyload direct/specific Icon React Component based on "oneOf" icon name (string)
  endAdornment: PropTypes.any,
  /** optional boolean to reset default icon component styles  */
  endAdornmentStylesReset: PropTypes.bool,
  /** Button id */
  id: PropTypes.string.isRequired,
  /** Optional onClick function */
  onClick: PropTypes.func,
  /** optional icon start adornment displayed before button text - PASS IN SPECIFIC ICON COMPONENT */
  // TODO: lazyload direct/specific Icon React Component based on "oneOf" icon name (string)
  startAdornment: PropTypes.any,
  /** optional boolean to reset default icon component styles  */
  startAdornmentStylesReset: PropTypes.bool,
  /** Optional custom styles */
  styles: PropTypes.object,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** type: default button  */
  type: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  /** button variant */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
};

ButtonComponent.defaultProps = {
  branding: 'nm',
  children: '',
  className: null,
  disabled: false,
  endAdornment: null,
  startAdornment: null,
  styles: null,
  type: 'button',
  themeType: 'lightTheme',
  variant: 'primary',
};

export default ButtonComponent;
