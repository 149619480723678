import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Col } from '../../../../foundations/Grid';
import Link from '../../../../components/Link';

const hasAdditionalDisclaimers = (disclaimerFootnotes, customDisclaimers) => (disclaimerFootnotes && disclaimerFootnotes.length > 0)
  || (customDisclaimers && customDisclaimers.length > 0);

export const GeneralDisclaimerColStyled = styled(Col)`
  ${({ disclaimerFootnotes, customDisclaimers, theme }) => css`
    padding-top: ${rem(32)};
    padding-bottom: ${rem(16)};
    column-count: ${theme.mediumUp() ? '2' : '1'};
    ${hasAdditionalDisclaimers(disclaimerFootnotes, customDisclaimers)
      && `
        border-top: 1px solid ${theme.colors.neutral.gray};
      `}
  `}
`;

export const LinkStyled = styled(Link)`
  && {
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    font-weight: 500;
    text-decoration: none;
  }
`;
